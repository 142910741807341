import React, { useContext } from 'react'
import ReactDOM from 'react-dom';
import { TrainingContext } from '../../store/training/TrainingContext';

function ControlBar({ rootRef }) {
    const { nextStep, prevStep, currentStep, steps, stopTraining, isActive } = useContext(TrainingContext)
    const step = steps.find((item) => item.index === currentStep)

    const handlePrev = () => {
        step?.prevCb && step.prevCb()
        prevStep()
    }

    const handleNext = () => {
        step?.nextCb && step.nextCb()
        nextStep()
    }

    return rootRef && isActive ? ReactDOM.createPortal(
        <div className='training-control' style={{ pointerEvents: 'auto' }}>
            <div className='training-control__inner'>
                <span className='training-control__title'>НАВИГАЦИЯ ПО ЛИЧНОМУ КАБИНЕТУ</span>
                <div className='training-control__buttons'>
                    {currentStep ? <div className='training-control__buttons-prev' onClick={handlePrev}>Назад</div> : ''}
                    <div className='training-control__buttons-disable' onClick={() => stopTraining()}>отключить навигацию</div>
                    {!step?.last ? <div className='training-control__buttons-next' onClick={handleNext} >Далее</div> : ''}
                </div>
            </div>
        </div>,
        rootRef
    ) : ''
}

export default ControlBar
