import {
    DIALOGS_GET,
    DIALOGS_IS_UPDATED,
    DIALOGS_UPDATE_FIXED,
    DIALOGS_FILTERED_GET,
    DIALOGS_UPDATE_CHATS,
    DIALOGS_PENDING,
    SET_CHART,
    SET_CHANNELS,
    RESET_MESSAGE_COUNT,
    DIALOGS_DISCONNECT,
} from '../types';

const handlers = {
    [DIALOGS_GET]: (state, {payload}) => ({ ...state, list: payload,  pending: false, isConnected: true }),
    [DIALOGS_IS_UPDATED]: (state, {payload}) => ({...state, isUpdated: payload}),
    [DIALOGS_UPDATE_FIXED]: (state, {payload}) => ({...state, list: payload}),
    [DIALOGS_FILTERED_GET]: (state, {payload}) => ({...state, filtered: payload}),
    [DIALOGS_UPDATE_CHATS]: (state, {payload}) => ({
        ...state,
        list: state.list.map(item => {
            for(let messageKey in payload) {
                let message = payload[messageKey];
                if(message.chat_id == item.id) {
                    return { ...item, last_message: message, unread_messages_count: item.unread_messages_count + 1 }
                }
            }
            return item;
        })
    }),
    [SET_CHANNELS]: (state, { channels }) => ({ ...state, channels }), 
    [SET_CHART]: (state, { chat }) => ({ ...state, chat }),
    [DIALOGS_PENDING]: (state) => ({ ...state, isConnected: true }),
    [RESET_MESSAGE_COUNT]: (state, { id }) => {
        const list = state.list.map((item) => {
            if(item.id === id) {
                item.unread_messages_count = 0;
            }

            return item;
        })
        
        return { 
            ...state, 
            list
        }
    },
    [DIALOGS_DISCONNECT]: (state) => ({ ...state, list: [], chat: null, channels: [], isConnected: false }),
    DEFAULT: state => state
}

export const dialogsReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
