var routes = require('./routes.json');

function Route () {
    var args = Array.prototype.slice.call(arguments);
    var name = args.shift();
    if(routes[name] === undefined) {
            console.log('Error');
    } else {
        return '/' + 
            routes[name].path
            .split('/')
            .map(str => str[0] === ":" ? args.shift() : str)
            .join('/');
    }
}

export default Route;