import * as React from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../store/user/UserContext";
import route from "./route";

const LoggedInRoute = ({
    component: Component,
    // isLogged = false,
    ...otherProps
}) => {
    
    const { pathname } = useLocation();
    const { isAuthorized, pending, user } = React.useContext(UserContext);

    if(!isAuthorized && !pending) {
        return <Redirect to={{pathname: route('profile.login'), state: { redirectUrl: pathname }}}  />
    }

    return (
        <>
            <Route
                render={otherProps => (
                    <>
                        <Component {...otherProps} />
                    </>
                )}
            />
        </>
    )
};

export default LoggedInRoute;
