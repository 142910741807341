import React, { useContext } from 'react'
import { ModalContext } from '../store/modal/ModalContext'
import Button from '../UI/Button'
import Modal from '../UI/Modal'

function ForgotPassSuccess() {
    const { hideModal } = useContext(ModalContext)

    return (
        <Modal id="forgotPassSuccess">
            <div className="modal__dialog-text">
                Инструкции для восстановления пароля отправлены на ваш почтовый ящик.
            </div>
            <Button type="button" color="main" onClick={() => hideModal('forgotPassSuccess')}>Ок</Button>
        </Modal>
    )
}

export default ForgotPassSuccess
