import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../store/app/AppContext";
import { DialogContext } from "../../store/dialog/DialogContext";
import { configs } from "../../utils/config";
import DialogForm from "./dialogForm";
import { UserContext } from "../../store/user/UserContext";
import Message from "../../components/Chat/Message";
import { useWindowSize } from "../../utils/useWindowSize";
import { ChatsUserThread, ChatsUserMobileThread } from '../../utils/NotificationLoader';
import classNames from "classnames";
import { DialogsContext } from "../../store/dialogs/DialogsContext";
import supportAvatar from '../../img/svg/user_headset.svg'
import moment from "moment";

export default function Dialog(props) {
    let { id } = useParams();
    let config = configs.getToken();

    const {
        headerBack,
        headerTitle,
        setHeaderMobile, 
        hideFooterMobile,
        hideTabbarMobile,
        fixedHeaderMobile,
        setHideFooterMobile,
        setHideTabbarMobile,
        setFixedHeaderMobile,
    } = useContext(AppContext); 

    const { connectToSocket: chatConnect, messages, data, sendMessage, upload, disconnectSocket } = useContext(DialogContext);
    const { chat, resetMessages, list } = useContext(DialogsContext);
    const { user } = useContext(UserContext);

    const [width, height] = useWindowSize();
    
    useEffect(() => {
        if(list.find((item) => item.id === chat.id)?.unread_messages_count)
            resetMessages(chat.id);
    }, [messages, list])

    useEffect(() => {
        chat && chatConnect(chat.id);

        return () => disconnectSocket();
    }, [chat])

    const chatTitle = chat?.title.split('-')[1];
    const chatImage = chat?.image ? config.static + chat?.image : '';

    useEffect(() => {
        if(!headerBack) {
            setHeaderMobile(
                <div className={classNames({ "chats__user": true, "chats__user--mobile": true, [`chats__user--${user.gender}`]: true })} >
                    <div className="chats__list-item-avatar" style={{ backgroundImage: `url(${supportAvatar})` }} />
                    <div className="chats__user-info">
                        <div className="chats__user-name">Поддержка</div>
                        <div className="chats__user-online">В сети</div>
                    </div>
                </div>, 'channels.list'
            );
        } 

        if(!hideFooterMobile) setHideFooterMobile();
        if(!fixedHeaderMobile) setFixedHeaderMobile(true);
        if(!hideTabbarMobile) setHideTabbarMobile();
    }, [headerTitle, hideFooterMobile, fixedHeaderMobile, setHideFooterMobile, setFixedHeaderMobile, setHeaderMobile]);
    
    const prepareData = (data) => {
        let days = {};
        let result = [];
        data.map(message => {
            if(!(message.human?.date in days))
                days[message.human?.date] = [];

            days[message.human?.date].push(message);
        })
        for(let date in days) {
            let messages = days[date];
            result.push({
                date: date,
                messages: messages
            })
        }

        return result;
    }

    let days = messages ? prepareData(messages) : [];

    const dates = [];
    
    return (
        <section className="chats__section section">
        <div className="section__container container">
            <div className={classNames('dialog', { [`dialog--${user.gender}`]: true })} style={{ height, minHeight: 200 }}>
                {data ? <>
                <div className="chats__user">
                    <div className="chats__user-avatar" style={{ backgroundImage: `url(${supportAvatar})` }} />
                    <div className="chats__user-info">
                        <div className="chats__user-name">Поддержка</div>
                        <div className="chats__user-online">В сети</div>
                    </div>
                </div>
                <div className="dialog__messages">
                    {days.map((day, index) => (
                        <React.Fragment key={index}>
                            {day.messages.map((message, key) => (
                                    <Message key={key} message={message} user={user} config={config} /> 
                            ))}
                            <div className="dialog__messages-date">
                            {!!day.messages.length && !!user && day.messages.map((message, key) => !message.hided &&
                                <div key={'message' + message.id}>
                                    {!dates.includes(message.human?.date) && dates.push(message?.human?.date) && (
                                        <div className="dialog__messages-date-badge-holder" key={10000 + key}>
                                            <div className="dialog__messages-date-badge">
                                                {message.human && moment(message.human?.date, 'DD.MM.YY').format('YYYY') === moment().format('YYYY')
                                                    ? moment(message.human?.date, 'DD.MM.YY').format('DD MMMM')
                                                    : message.human?.date
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            </div>
                        </React.Fragment>
                    ))}   
                </div>
                <DialogForm onMessage={sendMessage} upload={upload} />
                </>
                : <ChatsUserThread/>}
            </div>
        </div>
    </section>
    )
}