import { useMemo, useState } from "react"

const physicalValues = {
    none: 1.2,
    small: 1.375,
    medium: 1.55,
    large: 1.72
}

const maleCalories = ({ age, weight, height, desiredWeight, trainingFrequency }) => {
    const result = Math.trunc((88.36 + (13.4 * weight) + (4.8 * height) - (5.7 * age)) * physicalValues[trainingFrequency])

    if(desiredWeight < weight) return result - 500;
    if(desiredWeight > weight) return result + 250;
    return result;
}

const femaleCalories = ({ age, weight, height, desiredWeight, trainingFrequency }) => {
    const result = Math.trunc((447.6 + (9.2 * weight) + (3.1 * height) - (4.3 * age)) * physicalValues[trainingFrequency])
    
    if(desiredWeight < weight) return result - 200;
    if(desiredWeight > weight) return result + 150;
    return result;
}

export const getCalories = (values, gender) => {
    const genders = {
        male: maleCalories(values),
        female: femaleCalories(values)
    }

    return genders[gender]
}   

export const UseSelectState = (initValues) => {

    const { age, weight, height, desiredWeight, trainingFrequency } = initValues

    const [ageValue, setAgeValue] = useState(age)
    const [weightValue, setWeightValue] = useState(weight)
    const [heightValue, setHeightValue] = useState(height)
    const [desiredWeightValue, setDesiredWeightValue] = useState(desiredWeight)
    const [trainingFrequencyValue, setTrainingFrequencyValue] = useState(trainingFrequency)

    const getChagedValues = () => {
        let changedValues = {}

        if(!ageValue || !weightValue || !heightValue || !desiredWeightValue || !trainingFrequencyValue) return {}

        if(ageValue && age !== ageValue) changedValues = { ...changedValues, age: ageValue }
        if(weightValue && weight !== weightValue) changedValues = { ...changedValues, weight: weightValue }
        if(heightValue && height !== heightValue) changedValues = { ...changedValues, height: heightValue }
        if(desiredWeightValue && desiredWeight !== desiredWeightValue) changedValues = { ...changedValues, want_weight: desiredWeightValue }
        if(trainingFrequencyValue && trainingFrequency !== trainingFrequencyValue) changedValues = { ...changedValues, physical: trainingFrequencyValue }
        
        return changedValues
    }

    return [
        {
            age: { value: ageValue, set: setAgeValue },
            weight: { value: weightValue, set: setWeightValue },
            height: { value: heightValue, set: setHeightValue },
            desiredWeight: { value: desiredWeightValue, set: setDesiredWeightValue },
            trainingFrequency: { value: trainingFrequencyValue, set: setTrainingFrequencyValue },
        },
        getChagedValues(),
        !!Object.keys(getChagedValues()).length
    ]
}