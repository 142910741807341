import React, { useContext, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { UseProgressSelectStyles } from '../../utils/selectStyles';
import { ProgressContext } from '../../store/progress/ProgressContext';
import { useForm, Controller } from 'react-hook-form';
import { UserContext } from '../../store/user/UserContext';
import javascriptDebounce from 'javascript-debounce';
import { UseUpdateUser } from '../../utils/progress';
import { trainingConfigs, UseTraining, UseTrainingConfigs } from '../../utils/training';
import { useWindowSize } from '../../utils/useWindowSize';

function Day() {
    const { activities, addNewActivity, currentDay, getCurrentDay, loading, deleteActivity, currentDayTime } = useContext(ProgressContext)
    const { user, updateUserCalories, getUserCalories } = useContext(UserContext)

    const { register, errors, handleSubmit, reset } = useForm()

    useEffect(() => {
        currentDay?.time !== currentDayTime && javascriptDebounce(getCurrentDay(currentDayTime), 150)
    }, [currentDayTime])

    const { addActivity, removeActivity, calcActivity } = UseUpdateUser()

    const practiceSelectStyles = UseProgressSelectStyles();

    const submit = (data) => {
        addNewActivity({ activity: 'eat', calories: data.kcal, time: currentDayTime }, updateUserCalories);
        reset({})
    }
    const eatActivities = currentDay?.activities.filter((item) => item.activity === 'eat')

    const handleDelete = (activity) => {
        deleteActivity(activity.id, getUserCalories);
    }   

    const { register: trainingRegister, setStepCallback } = UseTraining();
    const configs = UseTrainingConfigs();

    const [width] = useWindowSize()

    const registers = useMemo(() => {
        return [
            trainingRegister(configs.progress[0], 1),
        ]
    }, [width]);
    
    if(loading) return  <div className={`progress__day-loader progress__day-loader--${user.gender}`}>
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </div>

    return (
            currentDay && !loading &&
                <div className="progress__day">
                    <div className="progress__day-body">
                        <div className="progress__day-calories">
                            <form className="progress__day-calories-add" onSubmit={handleSubmit(submit)}>
                                <div ref={registers[0]} style={{ width: '100%' }}>
                                    <input 
                                        as='input'
                                        className="progress__day-calories-add-count" 
                                        placeholder='Добавить ккал по съеденному блюду' 
                                        type="text"
                                        name='kcal'
                                        ref={register({
                                            required: true
                                        })} />
                                </div>
                                <button className="progress-button progress__day-calories-add-button"></button>
                            </form>
                            <div className='progress__day-list'>
                                    {eatActivities.map((item, index) => (
                                        <div className='progress__day-list-item' key={index}>
                                            <div className='progress__value progress__day-value'>{Math.abs(item.calories)} ККАЛ</div>
                                            <button className='progress__day-list-item-delete' onClick={() => handleDelete(item)} type='button' />
                                        </div>
                                    ))}
                            </div>
                            <div className="progress__day-calories-counter">
                                Итог <b>{user.calories.lost_today}</b> ккал из <b>{Math.trunc(user.calories.daily_goal)}</b> ккал
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default Day
