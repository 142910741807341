import { CHANNELS_LOADING, CHANNELS_DISCONNECT, CHANNELS_LOADING_ERROR, CHANNELS_LOADING_SUCSSES, CHANNELS_PENDING, SET_ARTICLES, SET_CHANNELS_MESSAGES, SET_CURRENT_ACTICLE, CHANNELS_DELETE_MESSAGE } from '../types';

const handlers = {
    [SET_CURRENT_ACTICLE]: (state, { article }) => ({ ...state, currentArticle: article }),
    [CHANNELS_LOADING]: (state) => ({ ...state, loading: true, error: false }),
    [CHANNELS_LOADING_ERROR]: (state) => ({ ...state, loading: false, error: true }),
    [CHANNELS_LOADING_SUCSSES]: (state) => ({ ...state, loading: false, error: false }),
    [SET_CHANNELS_MESSAGES]: (state, { messages }) => ({ ...state, articles: [...messages, ...state.articles], pending: false }),
    [SET_ARTICLES]: (state, { articles }) => ({ ...state, articles }),
    [CHANNELS_PENDING]: (state) => ({ ...state, pending: true }),
    [CHANNELS_DISCONNECT]: (state) => ({ ...state, pending: true, articles: [] }),
    [CHANNELS_DELETE_MESSAGE]: (state, { id }) => ({...state, articles: state.articles.filter((item) => item.id !== id )}),
    DEFAULT: state => state
}

export const channelsReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
