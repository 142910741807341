import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import ContentEditable from 'react-contenteditable'
import Button from '../../UI/Button';
import {useForm} from 'react-hook-form';
import { faSmileBeam, faPaperPlane, faImages } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import WrongTypeFile from '../../modals/WrongTypeFile';
import { ModalContext } from '../../store/modal/ModalContext';
import { UserContext } from '../../store/user/UserContext';
import ControlBar from '../../components/Training/ControlBar';
import { trainingConfigs, UseTraining, UseTrainingConfigs } from '../../utils/training';
import { useWindowSize } from '../../utils/useWindowSize';

export default React.memo(function DialogForm({onMessage, upload}) {
    const [message, setMessage] = useState('');
    const {handleSubmit, register, setValue, watch, reset} = useForm();
    const { showModal } = useContext(ModalContext);
    const rootRef = useRef();

    const file = watch('file');

    const { register: trainingRegister, setStepCallback } = UseTraining();
    
    useEffect(() => {
        register('text', {required: true});
    }, [register])

    useEffect(() => {
        setValue('text', message);
    }, [message, setValue])

    const handleChange = evt => {
        setMessage(evt.target.value.replaceAll("&nbsp;", "").replaceAll("<div>", "").replaceAll("</div>", "").replaceAll("<br>", ""));
    };
  
    const onSubmit = data => {
        onMessage(data);
        reset({})
        setMessage('');
    };

    const handleKeyDown = (event) => {
        if (event.code === 'Enter') {
            event.preventDefault();
            handleSubmit(onSubmit)(event);
        }
    };

    const checkPhoto = (e) => {
        const file = e.target.files[0]
        if(file.type.split('/')[0] !== 'image') {
            showModal('wrongType')
            reset({})
        }
    }

    const configs = UseTrainingConfigs();
    const [width, height] = useWindowSize()


    // const registers = useMemo(() => {
    //     return [
    //         trainingRegister(configs.support[0], 1),
    //         trainingRegister(configs.support[1], 2),
    //         trainingRegister(configs.support[2], 3),
    //     ]
    // }, [width]);

    return (
        <>
            <div className="dialog__form" ref={rootRef}>
                <form onSubmit={handleSubmit(onSubmit)} className="dialog__form-inner">
                    <div className="dialog__form-message-wrap">
                        <ContentEditable
                            html={message}
                            disabled={false}
                            onChange={handleChange}
                            onKeyPress={handleKeyDown}
                            tagName='div'
                            className='dialog__form-message'
                        />
                    </div>
                    <input ref={register} onChange={checkPhoto} name="file" type="file" id="attachFile" style={{display:'none'}} />
                    <div className={classNames({ 'dialog__form-attach': true, '--file': file?.length })} >
                        <Button type="button" className="dialog__form-icon" size="small" htmlFor="attachFile" as="label" icon={faImages}/>
                        <span className='dialog__form-attach-mark'>!</span>
                    </div>
                    <Button className="dialog__form-send" type="submit" size="small" color='main' iconPos="right" icon={faPaperPlane}>Отправить</Button>
                </form>
                <WrongTypeFile text={'Отправить можно только изображения'} />
            </div>
            <ControlBar rootRef={rootRef.current} />
        </>
    )
})