import React, { useContext } from 'react'
import Button from '../UI/Button'
import Modal from '../UI/Modal'
import { ModalContext } from '../store/modal/ModalContext'

function WrongTypeFile({ text }) {
    const { hideModal } = useContext(ModalContext)

    return (
        <Modal id="wrongType">
            <div className="modal__dialog-text">
                {text}
            </div>
            <Button type="button" color="main" onClick={() => hideModal('wrongType')}>Ок</Button>
        </Modal>
    )
}

export default WrongTypeFile
