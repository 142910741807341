import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../store/app/AppContext';
import { ModalContext } from '../../store/modal/ModalContext';
import { UserContext } from '../../store/user/UserContext'
import Select from 'react-select'
import WrongTypeFile from '../../modals/WrongTypeFile';
import { UseProfileSelectStyles } from '../../utils/selectStyles'
import Parameters from '../../components/Parameters/Parameters';
import Button from '../../UI/Button';
import { UseSelectState, getCalories } from '../../utils/profile';
import { trainingConfigs, UseTraining, UseTrainingConfigs } from '../../utils/training';
import { useWindowSize } from '../../utils/useWindowSize';
const options = require('./_options.json')

function ProfileInfo(props) {
    const {
        setShowProfileHeader,
    } = useContext(AppContext)
    
    const { showModal } = useContext(ModalContext)
    const { user, updateUserQuestion, loading } = useContext(UserContext)
    const [photo, setPhoto] = useState(user.avatar)
    const selectStyles = UseProfileSelectStyles(user.gender);
    const [width] = useWindowSize()

    const [
        selectState, 
        changes, 
        isChangesValid
    ] = UseSelectState({ age: user.question?.age, 
                        weight: user.question?.weight, 
                        height: user.question?.height, 
                        desiredWeight: user.question?.want_weight,
                        trainingFrequency: user.question?.physical })

    useEffect(() => {
        setShowProfileHeader(true, 'Профиль', false)
    }, []);

    const handleChange = () => {
        updateUserQuestion(changes)
    }

    const { value: age, set: setAge } = selectState.age
    const ageOptions = options.age

    const { value: weight, set: setWeight } = selectState.weight
    const weightOptions = options.weight

    const { value: height, set: setHeight } = selectState.height
    const heightOptions = options.height

    const { value: desiredWeight, set: setDesiredWeight } = selectState.desiredWeight
    const desiredWeightOptions = options.desiredWeight
    
    const { value: trainingFrequency, set: setTrainingFrequency } = selectState.trainingFrequency
    const trainingFrequencyOptions = options.trainingFrequency

    const calories = useMemo(() => Math.trunc(user.calories?.daily_goal), [user])
    
    const handleChangePhoto = (e) => {
        const file = e.target.files[0]
        if(!e.target.files[0]) return
        if(file.type.split('/')[0] !== 'image') {
            showModal('wrongType')
            e.target.value = ''
            return
        }

        setPhoto(URL.createObjectURL(file))
    }

    const { register, setStepCallback } = UseTraining();
    const configs = UseTrainingConfigs()

    const registers = useMemo(() => {
        return [
            register(configs.profile[0], 1),
            register(configs.profile[1], 2),
            register(configs.profile[2], 3),
            register(configs.profile[3], 4),
            register(configs.profile[4], 5),
            register(configs.profile[5], 6),
            register(configs.profile[6], 7),
            register(configs.profile[7], 8),
        ]
    }, [width]);

    return (
            user.id && 
            <>
                <div className="profile__header">
                    <div className="profile__info">
                        <label className='profile__info-avatar' style={{backgroundImage: `url(${photo})`}}>
                            <input type="file" onChange={handleChangePhoto}/>
                        </label>
                        <div className="profile__info-name">{user.email}</div>
                    </div>
                    {width >= 650 && <Parameters location='profile' />}
                </div>
                <div className='profile__inner' ref={registers[5]}>
                    <div className="profile__body">
                        <div className="profile__proportions">
                            <div ref={registers[0]}>
                                <Select
                                    onChange={(e) => setWeight(e.value)}
                                    value={weightOptions.find((item) => item.value === weight)}
                                    noOptionsMessage={() => 'не найдено'}
                                    classNamePrefix='react-select'
                                    placeholder='укажите ваш вес'
                                    className='profile__proportions-item-select' 
                                    options={weightOptions} 
                                    styles={selectStyles} />
                            </div>
                            <div ref={registers[1]}>
                                <Select 
                                    onChange={(e) => setAge(e.value)}
                                    value={ageOptions.find((item) => item.value === age)} 
                                    isSearchable={false}
                                    noOptionsMessage={() => 'не найдено'}
                                    classNamePrefix='react-select'
                                    placeholder='укажите ваш возраст'
                                    className='profile__proportions-item-select' 
                                    options={ageOptions} 
                                    styles={selectStyles} />
                            </div>
                            <div ref={registers[2]}>
                                <Select
                                    onChange={(e) => setDesiredWeight(e.value)}
                                    value={desiredWeightOptions.find((item) => item.value === desiredWeight)}
                                    noOptionsMessage={() => 'не найдено'}
                                    classNamePrefix='react-select'
                                    placeholder='укажите ваш желаемый вес'
                                    className='profile__proportions-item-select' 
                                    options={desiredWeightOptions} 
                                    styles={selectStyles} />
                            </div>
                            <div ref={registers[3]}>
                                <Select 
                                    onChange={(e) => setHeight(e.value)}
                                    value={heightOptions.find((item) => item.value === height)}
                                    noOptionsMessage={() => 'не найдено'}
                                    classNamePrefix='react-select'
                                    placeholder='укажите ваш рост'
                                    className='profile__proportions-item-select' 
                                    options={heightOptions} 
                                    styles={selectStyles} />
                            </div>
                            <div ref={registers[4]}>
                                <Select 
                                    onChange={(e) => setTrainingFrequency(e.value)}
                                    value={trainingFrequencyOptions.find((item) => item.value === trainingFrequency)}
                                    isSearchable={false}
                                    noOptionsMessage={() => 'не найдено'}
                                    classNamePrefix='react-select'
                                    placeholder='укажите частоту тренировок' 
                                    className='profile__proportions-item-select' 
                                    options={trainingFrequencyOptions} 
                                    styles={selectStyles} />
                            </div>
                        </div>
                            <div className="profile__norm" ref={registers[7]}>
                                <div className="profile__norm-title">Целевая норма ккал</div>
                                <div className="profile__norm-value" ref={registers[6]}>
                                    {loading ? 
                                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> : 
                                        calories ? calories : '----'}
                                </div>
                            </div>
                        </div> 
                        <Button className='profile__button' color='main' loading={loading} onClick={handleChange} disabled={!isChangesValid}>Сохранить</Button>
                    </div>
            <WrongTypeFile text={'Выберите картинку'} />
        </>
    )
}

export default React.memo(ProfileInfo)
