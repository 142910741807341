import React, { useContext } from 'react'
import Button from '../UI/Button'
import Modal from '../UI/Modal'
import { ModalContext } from '../store/modal/ModalContext'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../store/user/UserContext'

function Unsubscribe({ text, theme }) {
    const { hideModal, showModal } = useContext(ModalContext)
    const { unsubscribeFetch } = useContext(UserContext)

    const iconColor = theme === 'male' ? '#3b7ac3' : '#f99494'

    return (
        <Modal id="unsubscribe" className='unsubscribe'> 
            <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: 77, margin: '0 auto 30px auto', display: 'block', color: iconColor }} />
            <div className="modal__dialog-text">
                {text}
            </div>
            <div className='unsubscribe__buttons'>
                <Button type="button" color="main" onClick={() => { unsubscribeFetch(); hideModal('unsubscribe'); showModal('successUnsubscribe') }}>Да</Button>
                <Button type="button" color="main" onClick={() => hideModal('unsubscribe')}>Нет</Button>
            </div>
        </Modal>
    )
}

export default Unsubscribe
