import { useWindowSize } from './useWindowSize'

export const UseProgressSelectStyles = () => {
    const [width, heigth] = useWindowSize()

    const selectStyles = {
        option: (provided) => ({
          ...provided,
          cursor: 'pointer',
          borderBottom: '1px solid #ccc',
          backgroundColor: '#F0F0F0',
          color: '#000',
          fontSize: 16,
        }),
        placeholder: (provided) => ({
            ...provided,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            height: '100%',
            color: '#000',
            fontSize: width <= 640 ? 13 : 22,
            padding: '0 40px'
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            display: 'none'
        }),
        container: (provided) => ({
            ...provided,
            textAlign: 'center',
            outline: 'none',
            border: '1px solid #B0B0B0',
            width: '45%',
            backgroundColor: '#F0F0F0'
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: width <= 640 ? 13 : 22,
        }),
        valueContainer: (provided) => ({
            ...provided,
            display: 'flex',
            justifyContent: 'center',
            color: '#000',
            height: '100%',
            fontSize: width < 768 ? 20 : 22,
            padding: '0 20px 0 10px',

        }),
        control: (provided) => ({
            ...provided,
            background: "url('../img/svg/arrow-down.svg') center no-repeat",
            border: 'none',
            borderRadius: 10,
            textAlign: 'center',
            boxShadow: "none",
            height: '100%',
            cursor: 'pointer'
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
        })
    }

    return selectStyles
}

export const UseProfileSelectStyles = (gender) => {
    const [width, heigth] = useWindowSize()

    const theme = {
        male: {
            backgroundColor: '#3b7ac3',
            color: '#fff',
            borderColor: '#fff',
            placeholderColor: '#fff'
        },
        female: {
            backgroundColor: '#f8e2e2',
            color: '#000',
            borderColor: 'pink',
            placeholderColor: '#ccc'
        }
    }

    const selectStyles = {
        option: (provided) => ({
          ...provided,
          borderBottom: `1px solid ${theme[gender].borderColor}`,
          backgroundColor:  theme[gender].backgroundColor,
          color: theme[gender].color,
          fontSize: 22,
          cursor: 'pointer',
        }),
        placeholder: (provided) => ({
            ...provided,
            textAlign: 'center',
            width: '100%',
            color: theme[gender].placeholderColor,
            padding: '0 25px 0 50px',
            fontSize: width <= 795 ? 14 : 22,
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            display: 'none'
        }),
        container: (provided) => ({
            ...provided,
            textAlign: 'center',
            borderRadius: 10,
            outline: 'none',
            marginTop: 10,
        }),
        valueContainer: (provided) => ({
            ...provided,
            display: 'flex',
            justifyContent: 'center',
            color: theme[gender].color,
            padding: '0 20px',
            fontSize: width < 768 ? 18 : 22,
        }),
        control: (provided) => ({
            ...provided,
            background: "url('../img/arrow-down2.svg') 5% no-repeat",
            minHeight: 48,
            border: 'none',
            borderRadius: 10,
            textAlign: 'center',
            boxShadow: "none",
            cursor: 'pointer'
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            maxHeight: 200,

        }),
        noOptionsMessage: (provided) => ({
            ...provided,
            borderBottom: '1px solid pink',
            backgroundColor: '#f8e2e2',
            color: theme[gender].color,
            fontSize: 22,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme[gender].color,
        })
    }


    return selectStyles
}