import { ADD_NEW_ACTIVITY, DELETE_ACTIVITY, PROGRESS_ERROR, PROGRESS_LOADING, PROGRESS_SET_ACTIVITIES, SET_CURRENT_DAY, SET_NEW_DAY } from "../types";

const handlers = {
    [PROGRESS_LOADING]: (state) => ({ ...state, loading: true, error: false }),
    [PROGRESS_ERROR]: (state) => ({ ...state, loading: false, error: true }),
    [PROGRESS_SET_ACTIVITIES]: (state, { activities }) => ({ ...state, activities, loading: false }),
    [SET_CURRENT_DAY]: (state, { day }) => ({ ...state, currentDay: day, loading: false }),
    [ADD_NEW_ACTIVITY]: (state, { activity }) => {
        const currentDay = { 
            ...state.currentDay, 
            activities: [...state.currentDay.activities, activity], 
            lost_calories: state.currentDay.lost_calories - activity.calories
        }
        return { 
            ...state, 
            loading: false, 
            currentDay
        }
    },
    [DELETE_ACTIVITY]: (state, { id }) => {
        const activity = state.currentDay.activities.find((item => item.id === id))
        const currentDay = { 
            ...state.currentDay, 
            activities: state.currentDay.activities.filter((item => item.id !== id)),
            lost_calories: state.currentDay.lost_calories + activity.calories
        }

        return {
            ...state,
            currentDay
        }
    },
    [SET_NEW_DAY]: (state, { currentDayTime }) => ({ ...state, currentDay: null, currentDayTime }),
    DEFAULT: state => state
}

export const progressReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT;
    return handler(state, action)
}