// import axios from 'axios'

// let api = axios.create({
//     baseURL: "https://api.giveaways.roimaster.site",
//     responseType: "json"
// });

// export default api

import axios from 'axios';
import {configs} from '../utils/config';
import { getToken } from './getToken';

export const getApi = () => {
    let config = configs.getToken();
    let token = getToken();

    let api = axios.create({
        baseURL: config.api,
        responseType: "json",
        headers: {
            common: {
                Authorization: token
            }
        }
    });

    api.interceptors.response.use((response) => {
        return response;
    }, function (error) {
        console.log(' e', error)
        // Do something with response error
        if (error?.response?.status === 401) {
            // console.log('unauthorized, logging out ...');
            // auth.logout();
            // router.replace('/auth/login');
        }
        return Promise.reject(error.response);
    });

    return api;
}

// export api
