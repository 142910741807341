import { SET_TABBAR_BUTTON, SET_HEADER_MOBILE, HIDE_FOOTER_MOBILE, FIXED_HEADER_MOBILE, HIDE_TABBAR_MOBILE, SHOW_PROFILE_HEADER } from "../types"

const handlers = {
    [SET_TABBAR_BUTTON]: (state, {button}) => ({...state, tabbarButton: button}),
    [SET_HEADER_MOBILE]: (state, {headerTitle, headerBack, headerBackParams}) => ({...state, headerTitle, headerBack, headerBackParams}),
    [HIDE_FOOTER_MOBILE]: (state) => ({...state, hideFooterMobile: true}),
    [HIDE_TABBAR_MOBILE]: (state) => ({...state, hideTabbarMobile: true}),
    [FIXED_HEADER_MOBILE]: (state, {fixedHeaderMobile}) => ({...state, fixedHeaderMobile}),
    [SHOW_PROFILE_HEADER]: (state, { value, title, isShowButton }) => {
        return {
            ...state, 
            showProfileHeader: value, 
            profileHeaderTitle: title, 
            showProfileHeaderButton: isShowButton
        }
    },
    DEFAULT: state => state
}

export const appReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}