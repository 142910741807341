import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../../store/app/AppContext';
import classNames from 'classnames';
import Route from '../../hoc/route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../store/user/UserContext';
import { ChannelsContext } from '../../store/channels/ChannelsContext';
import { prepareArticle } from '../../utils/channels';
import debounce from 'javascript-debounce';
import moment from 'moment';
import { DialogsContext } from '../../store/dialogs/DialogsContext';

const Loader = ({ theme }) => {
    return (
        <div className={`article__loader article__loader--${theme}`}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

function Article() {
    const {
        headerBack,
        headerTitle,
        setHeaderMobile,
        hideFooterMobile,
        hideTabbarMobile,
        fixedHeaderMobile,
        setHideFooterMobile,
        setHideTabbarMobile,
        setFixedHeaderMobile,
    } = useContext(AppContext);

    const { user } = useContext(UserContext);
    const { getCurrentArticle, currentArticle, loading, articles } = useContext(
        ChannelsContext,
    );
    const { channels } = useContext(DialogsContext)

    let { current, id } = useParams();
    const currentChannel = channels?.find((item) => item.alias === current)

    useEffect(() => {
        getCurrentArticle(id);
    }, [id]);

    const headerClasses = classNames('article__header', {
        [`article__header--${user.gender}`]: true,
    });
    const headerAvatarClasses = classNames({
        'article__header-avatar': true,
        [`article__header-avatar--${current}`]: true,
    });

    const currentDate = useMemo(() => articles?.find((item) => item.preview.id === +id)?.time || '', [articles])

    useEffect(() => {
            if (!headerBack && currentDate) {
            setHeaderMobile(
                <div className={headerClasses}>
                    <div className={headerAvatarClasses} />
                    <div className="article__header-info">
                        <div className="article__header-date">{moment.unix(currentDate).format('D MMMM')}</div>
                    </div>
                </div>,
                'channels.view',
                [current],
            );
        }

        if (!hideFooterMobile) setHideFooterMobile();
        if (!fixedHeaderMobile) setFixedHeaderMobile(true);
        if (!hideTabbarMobile) setHideTabbarMobile();
    }, [headerTitle, hideFooterMobile, fixedHeaderMobile, setHideFooterMobile, setFixedHeaderMobile, setHeaderMobile, id, currentDate]);
    const rootRef = useRef();


    const text = currentArticle?.text
    ? prepareArticle(currentArticle?.text)
    : '';
    
    return (
        <div className="article">
            <div className={headerClasses}>
                <Link
                    to={Route('channels.view', current)}
                    className="article__header-back">
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
                <div className={headerAvatarClasses} />
                <div className="article__header-info">
                    <div className="article__header-date">{moment.unix(currentDate).format('D MMMM')}</div>
                </div>
            </div>
            {!loading ? (
                <>
                    <div className="article__list">
                        <div className="article__list-item" dangerouslySetInnerHTML={{ __html: text }}></div>
                    </div>
                </>
            ) : (
                <Loader theme={user.gender} />
            )}
        </div>
    );
}

export default Article;
