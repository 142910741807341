import React from 'react';
import ReactDOM from 'react-dom';
import './styles/app.scss';
import * as serviceWorker from './serviceWorker';
import Layout from './hoc/Layout/Layout';
import "scroll-behavior-polyfill";
import { configs } from './utils/config';
import { UserState } from './store/user/UserState';
import smoothscroll from 'smoothscroll-polyfill';
import ReactGA from 'react-ga';
import { history } from './hoc/history';
import { Router } from 'react-router-dom';


smoothscroll.polyfill();
window.__forceSmoothScrollPolyfill__ = true;

configs.initialize().then(() => {
  // notifications.initialize(store);
  // initializeReactGA();
  // auth.initialize(store);     

// ReactGA
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
      <UserState>
        <Router history={history}>
          <Layout />
        </Router>
      </UserState>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
})