import {
    DIALOG_GET,
    DIALOG_OPEN,
    DIALOG_EMPTY,
    DIALOG_PENDING,
    DIALOG_GET_USER,
    DIALOG_SHOW_IMAGE,
    DIALOG_INPUT_TEXT,
    DIALOG_ADD_HISTORY,
    DIALOG_TOGGLE_CLIP,
    DIALOG_HIDE_MESSAGE,
    DIALOG_CHANGE_SCROLL,
    DIALOG_CHANGE_TYPING,
    DIALOG_MODIFY_UNREAD_MESSAGES,
    DIALOG_DELETE_MESSAGE,
} from "../types";

const handlers = {
    [DIALOG_GET]: (state, {messages, data}) => ({
        ...state, 
        messages: [...messages, ...state.messages], 
        ...data,
        scrollDown: true,
        count: messages.length, 
        error: false, 
        pending: false
    }),
    [DIALOG_OPEN]: (state, {payload}) => ({...state, payload: [], open: payload, fullClip: false}),
    [DIALOG_EMPTY]: (state, {initialState}) => initialState,
    [DIALOG_PENDING]: (state) => ({...state, pending: true}),
    [DIALOG_GET_USER]: (state, {payload}) => ({...state, payload, loaded: true}),
    [DIALOG_SHOW_IMAGE]: (state, {payload}) => ({...state, image: payload}),
    [DIALOG_INPUT_TEXT]: (state, {payload}) => ({...state, text: payload}),
    [DIALOG_TOGGLE_CLIP]: (state, {payload}) => ({...state, fullClip: payload}),
    [DIALOG_ADD_HISTORY]: (state, {payload}) => ({...state, payload: payload.concat(state.payload), scrollDown: false, count: state.count + payload.length}),
    [DIALOG_HIDE_MESSAGE]: (state, {payload}) => ({...state, payload: payload}),
    [DIALOG_CHANGE_SCROLL]: (state, {payload}) => ({...state, scroll: payload, scrollDown: true}),
    [DIALOG_CHANGE_TYPING]: (state, {payload}) => ({...state, typing: payload}),
    [DIALOG_MODIFY_UNREAD_MESSAGES]: (state, {payload}) => ({...state, unread: payload}),
    [DIALOG_DELETE_MESSAGE]: (state, { messageId }) => ({ ...state, messages: state.messages.filter((item) => item.id !== messageId )}),
    DEFAULT: state => state
}

export const dialogReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}