import React, { useContext, useEffect } from "react";
import { AppContext } from "../../store/app/AppContext";
import { UserContext } from "../../store/user/UserContext";
import Button from "../../UI/Button";
import { useForm } from "react-hook-form";
import Control from "../../UI/Control";
import { useHistory, useLocation } from "react-router";
import { ModalContext } from "../../store/modal/ModalContext";
import ForgotPass from "../../modals/ForgotPass";
import Route from "../../hoc/route";

function Login() {
  const { state, pathname } = useLocation();

  const { showModal } = useContext(ModalContext);

  const {
    hideFooterMobile,
    setHideFooterMobile,
    setHideTabbarMobile,
    hideTabbarMobile,
    setShowProfileHeader,
  } = useContext(AppContext);
  const { handleSubmit, register, errors: formErrors } = useForm();
  const { fetchLogin, errors, loading, isAuthorized } = useContext(UserContext);
  const history = useHistory();

  const onSubmit = (data) => {
    fetchLogin(data);
  };

  useEffect(() => {
    if (isAuthorized) history.push(state?.redirectUrl || Route("progress"));

    setShowProfileHeader(false);
    if (!hideFooterMobile) setHideFooterMobile();
    if (!hideTabbarMobile) setHideTabbarMobile();
  }, []);

  return (
    <div className="profile__login">
      <h1 className="profile__login-title">Вход</h1>
      <form className="profile__form" onSubmit={handleSubmit(onSubmit)}>
        <Control
          type="email"
          id="login-email"
          name="email"
          as="input"
          placeholder="email"
          ref={register({
            required: "Это поле обязательно",
          })}
          className="profile__form-input"
          error={formErrors.email?.message}
        />
        <Control
          type="password"
          id="login-password"
          name="password"
          as="input"
          placeholder="Пароль"
          ref={register({
            required: "Это поле обязательно",
          })}
          className="profile__form-input"
          error={formErrors.password?.message || errors.login}
        />
        <Button className="profile__form-button" color="main" loading={loading}>
          Войти
        </Button>
        <a
          className="profile__form-pass"
          onClick={() => showModal("forgotPass")}
        >
          забыли пароль?
        </a>
        <span className="profile__form-register">
          Нужен аккаунт? <a href="https://cheatmeal.pro/">регистрация</a>
        </span>
      </form>
      <ForgotPass />
    </div>
  );
}

export default Login;
