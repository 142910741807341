import { useParams } from "react-router-dom";
import { AppContext } from "../../store/app/AppContext";
import { configs } from "../../utils/config";
import { UserContext } from "../../store/user/UserContext";
import Message from "./ChannelMessage";
import moment from 'moment';
import { useWindowSize } from "../../utils/useWindowSize";
import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import { useRef } from "react";
import { ChannelsContext } from "../../store/channels/ChannelsContext";
import { DialogsContext } from "../../store/dialogs/DialogsContext";
import { UseAnchorMessage } from '../../utils/channels';
import 'moment/locale/ru';

export default function Channel(props) {
    let { current } = useParams();
    let config = configs.getToken();
    const { resetMessages, list } = useContext(DialogsContext);
    let currentChannel = list.find((item) => item.alias === current);

    const {
        headerBack,
        headerTitle,
        setHeaderMobile, 
        hideFooterMobile,
        hideTabbarMobile,
        fixedHeaderMobile,
        setHideFooterMobile,
        setHideTabbarMobile,
        setFixedHeaderMobile,
    } = useContext(AppContext)
    
    const { articles, connectToChannel, disconnectSocket, pending } = useContext(ChannelsContext);
    const { user } = useContext(UserContext);
    const messageRef = useRef()

    const { messages: anchoredMessages, 
            toggleMessage, 
            getRef, 
            scrollToAnchor, 
            isMessageSelected, 
            currentAnchoredMessage,
            isButtonActive,
            scrollBottom } = UseAnchorMessage(currentChannel?.id - 1, articles, pending, messageRef)
            
    const [width, height] = useWindowSize();

    // useEffect(() => {
    //     currentChannel?.id && connectToChannel(currentChannel?.id);

    //     return () => disconnectSocket();
    // }, [current, list])

    useEffect(() => {
        if(currentChannel?.unread_messages_count)
            resetMessages(currentChannel.id)
    }, [articles, currentChannel])

    const headerAvatarClasses = classNames({'article__header-avatar': true,  [`article__header-avatar--${current}`]: true})

    useEffect(() => {
        if(!headerBack && currentChannel?.title) {
            setHeaderMobile(
                <div className={classNames('article__header', {[`article__header--${user.gender}`]: true})}>
                    <div className={headerAvatarClasses} />
                    <div className="article__header-info">
                        <div className="article__header-date">{currentChannel?.title}</div> 
                    </div>
                </div>, 'channels.list'
            );
        } 

        if(!fixedHeaderMobile) setFixedHeaderMobile(true);
        if(!hideTabbarMobile) setHideTabbarMobile();
    }, [headerTitle, hideFooterMobile, fixedHeaderMobile, setHideFooterMobile, setFixedHeaderMobile, setHeaderMobile, currentChannel?.title]);
    

    const prepareData = (data) => {
        let days = {};
        let result = [];
        data.map(message => {
            if(!(message.human?.date in days))
                days[message.human?.date] = [];

            days[message.human?.date].push(message);
        })
        for(let date in days) {
            let messages = days[date];
            result.push({
                date: date,
                messages: messages
            })
        }

        return result;
    }

    const days = articles ? prepareData(articles) : [];
    const dates = [];
    
    // if(pending && !articles.length) return 
    //                     <div className={`progress__day-loader progress__day-loader--${user.gender}`}>
    //                         <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    //                     </div>

    return (
        <div className={classNames('channel', {[`channel--${user.gender}`]: true})} style={{ height }}>
            <div className={classNames('article__header', {[`article__header--${user.gender}`]: true})}>
                <div className={headerAvatarClasses} />
                <div className="article__header-info">
                    <div className="article__header-date">{currentChannel?.title}</div>
                </div>
            </div>
            {anchoredMessages?.length ? <div className='channel__anchor' onClick={() => scrollToAnchor(messageRef)}>
                <span className='channel__anchor-title'>
                    Закреплённое сообщение №{currentAnchoredMessage + 1}
                </span>
                {anchoredMessages?.map((message, index) => (
                    <p key={index} className={classNames('channel__anchor-message', { 'channel__anchor-message--active': message.id === anchoredMessages[currentAnchoredMessage]?.id})}>  
                        {articles.find((item) => item.id === message.id)?.preview.title}
                    </p>
                ))}
            </div> : ''}
            <div className="channel__messages" ref={messageRef}>                
                {days.map((day, index) => (
                    <React.Fragment key={index + 2}>
                        {day.messages.map((message, key) => (
                            <Message 
                                key={key} 
                                isSelected={isMessageSelected(message.id)}
                                anchorRef={getRef(message.id)} 
                                channelType={current} 
                                message={message} 
                                config={config}
                                toggleAnchor={() => toggleMessage(message.id)} 
                            /> 
                        ))}
                        <div className="dialog__messages-date" key={index + 100}>
                            {!!day.messages.length && !!user && day.messages.map((message, key) => !message.hided &&
                                <div key={'message' + message.id}>
                                    {!dates.includes(message.human?.date) && dates.push(message?.human?.date) && (
                                        <div className="dialog__messages-date-badge-holder">
                                            <div className="dialog__messages-date-badge">
                                                {message.human && moment(message.human?.date, 'DD.MM.YY').format('YYYY') === moment().format('YYYY')
                                                    ? moment(message.human?.date, 'DD.MM.YY').format('D MMMM')
                                                    : message.human?.date
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                ))}  
            </div>
            <div className={`bottom ${isButtonActive ? 'active' : ''}`} onClick={scrollBottom}></div>
        </div>
    )
}
