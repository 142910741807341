import swal from 'sweetalert2';
import { FRONT_URL, ENV } from '../config';
// import { auth } from './auth';
// import moment from 'moment';
// import { configs } from './config';

export const getConfigUrls = () =>
  fetch(FRONT_URL + '/config/'+ ENV +'.json')
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Looks like there was a problem. Status Code: ' +
          response.status);
        swal(
          'Looks like there was a problem. Status Code:' + response.status,
          '',
          'error'
        );

      }
    })
    .catch(error => {
      swal(
        'Looks like there was a problem.' + error,
        '',
        'error'
      );
    });
// export const dummyFetch = (url) =>
//   fetch(url)
//     .then(response => {
//       if (response.status === 200) {
//         return response.json();
//       } else {
//         console.log('Looks like there was a problem. Status Code: ' +
//           response.status);
//         swal(
//           'Looks like there was a problem. Status Code:' + response.status,
//           '',
//           'error'
//         );

//       }
//     })
//     .catch(error => {
//       swal(
//         'Looks like there was a problem.' + error,
//         '',
//         'error'
//       );
//     });

// const baseFetch = (url, method = 'get', body = false) => {
//   const myHeaders = new Headers();
//   const token     = auth.getToken();
//   let config      = configs.getToken();

//   if (token) {
//     myHeaders.append('Authorization', token);
//   }

//   myHeaders.append('Content-Type', 'application/json');

//   const myInit = {
//     method: method,
//     headers: myHeaders,
//     mode: 'cors',
//     cache: 'default'
//   };

//   if (body) {
//     myInit.body = JSON.stringify(body);
//   }

//   config = JSON.parse(config);

//   const myRequest = new Request(config.api + url, myInit);

//   return fetch(myRequest)
//     .then(response => {
//       if (response.status === 200) {
//         return response.json();
//       } else {
//         console.log('Looks like there was a problem. Status Code: ' +
//           response.status);
//         swal(
//           'Looks like there was a problem. Status Code:' + response.status,
//           '',
//           'error'
//         );

//       }
//     })
//     .catch(error => {
//       swal(
//         'Looks like there was a problem.' + error,
//         '',
//         'error'
//       );
//     });
// };

// export default baseFetch;

// export const getFetch = (url) =>
//   baseFetch(url);

// export const postFetch = (url, body) =>
//   baseFetch(url, 'POST', body);

// export const patchFetch = (url, body) =>
//   baseFetch(url, 'PATCH', body);

// export const uploadFile = file => {
//   const token = auth.getToken();
//   let config  = configs.getToken();
//   config      = JSON.parse(config);

//   return fetch(config.api + '/upload', {
//     headers: {
//       'Authorization': token,
//       "filename": moment().format('MM-DD-YYYY-HH:mm:ss') + '.' + file.type.split('/')[1]
//     },
//     method: 'POST',
//     body: file,
//   })
//     .then(response => response)
//     .then(response => {
//       if (response.status === 200) {
//         return response.json();
//       } else {
//         console.log('Looks like there was a problem. Status Code: ' +
//           response.status);
//         swal(
//           'Looks like there was a problem. Status Code:' + response.status,
//           '',
//           'error'
//         );

//       }
//     })
//     .catch(error => {
//       swal(
//         'Looks like there was a problem.' + error,
//         '',
//         'error'
//       );
//     });
// };