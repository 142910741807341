import { useReducer } from 'react';
import { configs } from '../../utils/config';
import { getToken } from '../../utils/getToken';
import { RESET_MESSAGE_COUNT, DIALOGS_GET, DIALOGS_PENDING, DIALOGS_UPDATE_CHATS, SET_CHANNELS, SET_CHART, DIALOGS_DISCONNECT } from '../types';
import {DialogsContext} from './DialogsContext';
import { dialogsReducer } from './DialogsReducer';

let dialogsSocket = null;
export const DialogsState = ({children}) => {

    let config = configs.getToken();
    let token = getToken();
    const initialState = {
        channels: [],
        list: [],
        filtered: [],
        isUpdated: false,
        dialogChatId: null,
        pending: false,
        isConnected: false
    };

    const [state, dispatch] = useReducer(dialogsReducer, initialState)

    const onMessage = (event, userId) => {
        if(!event.data) return
        const data = JSON.parse(event.data) 
        
        if(data.chats) {
            const channels = data.chats?.filter((item) => item.type === 'channel');
            const chat = data.chats?.find((item) => item.type === 'dialog');
            dispatch({ type: SET_CHANNELS, channels });
            dispatch({ type: SET_CHART, chat });
            dispatch({ type: DIALOGS_GET, payload: data.chats })
            return;
        }

        if(data.messages) {
            if(data.messages[0].user.id !== userId) {
                dispatch({ type: DIALOGS_UPDATE_CHATS, payload: data.messages });
            }
        }
    }

    const onClose = store => (event) => {
        // dispatch({ type: DIALOGS_DISCONNECT })
    }

    const onOpen = store => (event) => {
        // dispatch({ type: DIALOGS_PENDING })
    }

    const connectToSocket = (userId) => {
        if (dialogsSocket !== null) dialogsSocket.close();

        // connect to the remote host
        dialogsSocket = new WebSocket(config.ws + `?token=${token}`);

        // webdialogsSocket handlers
        dialogsSocket.onmessage = (event) => onMessage(event, userId);
        dialogsSocket.onclose = onClose(123);
        dialogsSocket.onopen = onOpen(123);
    }

    const disconnectSocket = () => {
        if(dialogsSocket) 
            dialogsSocket.close();
    }

    const resetMessages = (id) => {
        dispatch({ type: RESET_MESSAGE_COUNT, id })
    }

    return (
        <DialogsContext.Provider value={{
            ...state,
            connectToSocket,
            disconnectSocket,
            resetMessages
        }}>
            {children}
        </DialogsContext.Provider>
    )
}