import classnames from 'classnames';
import moment from 'moment';
import { useEffect } from 'react';
import LazyLoad, {forceCheck} from 'react-lazyload';
import maleAvatar from '../../img/male/default-profile.svg';
import femaleAvatar from '../../img/female/default-profile.svg';
import chatAvatar from '../../img/chat-avatar.png';

const avatars = {
    male: maleAvatar,
    female: femaleAvatar
}

export default function Message({ message, user, config }) {
    
    useEffect(() => {
        forceCheck();
    }, [])

    const getAvatar = () => {
        if(user.id !== message.user.id) return chatAvatar
        if(user.id === message.user.id) {
            return avatars[user.gender];
        }

        return message.user.avatar ? config.static + message.user.avatar : chatAvatar;
    }

    return (
        <div className={classnames("dialog__messages-item", {"dialog__messages-item--me" : user.id === message.user.id})} key={message.id}>
            <div className='dialog__messages-item-inner'>
                <div className="dialog__messages-item-avatar" style={{backgroundImage:`url(${getAvatar()})`}}/>
                <div className="dialog__messages-item-content">
                    {message.image && <div className="dialog__messages-item-image">
                        <LazyLoad
                            height={150}
                            offset={300}
                            scrollContainer={'.dialog__messages'}
                            overflow
                            resize>
                            <img src={message.image} alt="message" style={{ width: 'auto', height: '150px', maxWidth: '100%'}}/>
                        </LazyLoad>
                    </div>}
                    <div className="dialog__messages-item-text" dangerouslySetInnerHTML={{ __html: message.text }}></div>
                    <div className="dialog__messages-item-date">{moment.unix(message.time).format('HH:mm')}</div>
                </div>
            </div>
        </div>
    )   
}