import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ModalContext } from '../store/modal/ModalContext';
import { UserContext } from '../store/user/UserContext';
import Button from '../UI/Button';
import Control from "../UI/Control";
import Modal from "../UI/Modal";
import ForgotPassSuccess from './ForgotPassSuccess';

export default function ForgotPass (props) {
    const { showModal, hideModal, show } = useContext(ModalContext)
    const { errors, forgotPassFetch, loading } = useContext(UserContext) 
    const { errors: formErrors, handleSubmit, register } = useForm();

    const onSubmit = (data) => {
        forgotPassFetch(data)
    }
    
    useEffect(() => {
        if(!errors.forgotPass && !loading && show.indexOf('forgotPass') > -1) {
            showModal('forgotPassSuccess')
            hideModal('forgotPass')
        }
    }, [errors, loading])

    return (
        <Modal id="forgotPass" title="Восстановление пароля">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal__dialog-text">
                    Для восстановления пароля введите, пожалуйста, адрес вашей электронной почты
                </div>
                <Control error={formErrors.email?.message || errors.forgotPass} ref={register({
                        required: 'Это поле обязательно'
                    })} type="email" id="forgot-email" as="input" name="email" placeholder="E-mail" />
                <Button loading={loading} className="modal__dialog-button" color="main">Отправить</Button>
            </form>
            <ForgotPassSuccess />
        </Modal>
    );
}