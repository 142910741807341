import { isStepsEqual } from "../../utils/training"
import { ADD_NEW_STEP, NEXT_STEP, PREV_STEP, SET_CALLBACKS, TRANING_SET_ACTIVE } from "../types"

const handlers = {
    [TRANING_SET_ACTIVE]: (state, { active }) => ({ ...state, isActive: active, currentStep: 0 }),
    [ADD_NEW_STEP]: (state, { step }) => {
        if(!step.ref) return state;
        const currentStep = state.steps?.find(item => item.index === step.stepIndex) || { index: step.stepIndex, last: false, elements: { pointer: [], text: [], arrow: [] } }
        const newStepElements = step.stepConfigs.reduce((acc, item) => {
            const element = isStepsEqual(currentStep, item, step.id);
            if(element) {
                const newElements = currentStep.elements[item.type].filter((currentItem) => !isStepsEqual(currentStep, currentItem, step.id))
                return { ...acc, [item.type]: [...newElements,  { ...item, ref: step.ref, id: step.id }] }
            }
            
            currentStep.elements[item.type] = currentStep.elements[item.type] || []
            
            return { ...acc, [item.type]: [ ...currentStep.elements[item.type], { ...item, ref: step.ref, id: step.id }] }
        }, {})

        const steps = state.steps.filter((item) => item.index !== currentStep.index);
        return { 
            ...state, 
            steps: [...steps, { ...currentStep, elements: { ...currentStep.elements, ...newStepElements }, last: step.last }]
        }
    },
    [NEXT_STEP]: (state) => ({ ...state, currentStep: state.currentStep + 1 }), 
    [PREV_STEP]: (state) => ({ ...state, currentStep: state.currentStep - 1 }),
    [SET_CALLBACKS]: (state, { stepIndex, nextCb, prevCb }) => {
        const currentStep = state.steps.find((item) => item.index === stepIndex) || { index: stepIndex, last: false, elements: { pointer: [], text: [], arrow: [] } };
        const newSteps = state.steps.filter((item) => item.index !== stepIndex);

        return {
            ...state,
            steps: [...newSteps, { ...currentStep, nextCb, prevCb }]
        }
    },
    DEFAULT: state => state
}

export const trainingReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
