import moment from "moment"
import { useReducer } from "react"
import { getApi } from "../../utils/api"
import { prepareActivities } from "../../utils/progress"
import { ADD_NEW_ACTIVITY, DELETE_ACTIVITY, PROGRESS_ERROR, PROGRESS_LOADING, PROGRESS_SET_ACTIVITIES, SET_CURRENT_DAY, SET_NEW_DAY } from "../types"
import { ProgressContext } from "./ProgressContext"
import { progressReducer } from "./ProgressReducer"

export const ProgressState = ({children}) => {
    const api = getApi();

    const initialState = {
        activities: [],
        loading: false,
        error: false,
        currentDay: null,
        currentDayTime: moment().unix()
    }

    const [state, dispatch] = useReducer(progressReducer, initialState)

    const getActivities = async () => {
        try {
            const response = await api.get('/activities')
            dispatch({ type: PROGRESS_SET_ACTIVITIES, activities: prepareActivities(response.data) })
        } catch(e) {
            dispatch({ type: PROGRESS_ERROR })
        }
    }

    const addNewActivity = async (activity, userCb) => {
        try {
            const response = await api.post('/activity', activity);
            if(response.status === 200) {
                userCb(response.data.user)
                dispatch({ type: ADD_NEW_ACTIVITY, activity: response.data.activity })
            }
        } catch(e) {
            dispatch({ type: PROGRESS_ERROR })
        }
    }

    const getCurrentDay = async (date) => {
        dispatch({ type: PROGRESS_LOADING })
        try {
            const response = await api.get(`/daily-calories/${date}`);
            if(response.status === 200)
                dispatch({ type: SET_CURRENT_DAY, day: { ...response.data, time: date } })
        } catch(e) {
            dispatch({ type: PROGRESS_ERROR })
        }
    }

    const deleteActivity = async (id, getUserCb) => {
        try {
            const response = await api.delete(`/activity/${id}`);
            if(response.status === 200) {
                await getUserCb();
                dispatch({ type: DELETE_ACTIVITY, id })
            }
        } catch(e) {
            dispatch({ type: PROGRESS_ERROR })
        }
    }

    const setNewDay = (time) => {
        dispatch({ type: SET_NEW_DAY, currentDayTime: time })
    }

    return (
        <ProgressContext.Provider value={{
            ...state,
            getActivities,
            addNewActivity,
            getCurrentDay,
            deleteActivity,
            setNewDay
        }}>
            {children}
        </ProgressContext.Provider>
    )
}