import React, { useContext, useState } from 'react'
import Modal from '../UI/Modal'
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Control from '../UI/Control'
import { Link } from 'react-router-dom'
import { UserContext } from '../store/user/UserContext';

function Offer() {
    const [checkboxActive, setCheckboxActive] = useState(false);
    const { userPay, user, isAuthorized } = useContext(UserContext);

    const handleChange = () => setCheckboxActive(prev => !prev);

    const isPremiun = () => isAuthorized && user.categories.find((item) => item.alias.includes('premium'))

    const onClick = () => {
        if(isPremiun()) {
            userPay(849);
            return;
        }
        userPay(25);
    }

    // const isModalDisabled = () => {
    //     if(user.categories && user.categories.find((item) => item.alias === 'newbee')) {
    //         return false;
    //     }
    //     return true
    // }

    return (
        <Modal id="offert" className='offert' disableHide={false}> 
            <div className='offert__inner'>
                <h1 className="offert__title">
                    Специальное предложение только для новых пользователей
                </h1>
                <h2 className="offert__subtitle">
                    Возможность получить доступ к тарифу 
                    «Премиум» по суперцене
                </h2>
                <ul className='offert__list'>
                    <li className='offert__list-item'>Индивидуальный план питания</li>
                    <li className='offert__list-item'>Индивидуальный план тренировок</li>
                    <li className='offert__list-item'>Полезные статьи от профессиональных диетологов </li>
                    <li className='offert__list-item'>Круглосуточная поддержка </li>
                </ul>
                <div className='offert__pay'>
                    <h3 className='offert__pay-title'>
                        По акции вместо {isPremiun() ? 849 : <span>849</span>} руб
                    </h3>
                    <h4 className='offert__pay-subtitle'>
                        Доступ к тарифу "Премиум" на неделю за {isPremiun() ? '25' : '849'}, далее по тарифу
                    </h4>
                    <div className='offert__pay-controls'>
                        <button className='offert__pay-controls-button' disabled={!checkboxActive} onClick={onClick}>Оплатить {!isPremiun() ? '25' : '849'} руб</button>
                        <Control onClick={handleChange} icon={faCheck} type="checkbox" as="input" label="согласен на автоматическое списание" />
                    </div>
                </div>
                <p className='offert__policy'>
                    Нажимая на кнопку, вы соглашаетесь с условиями <Link to='/politics'>публичной оферты</Link>, 
                    действующим и <Link to='/politics'>тарифами</Link> сервиса, даете свое <Link to='/politics'>согласие на обработку персональных 
                    данных</Link> и на получение рекламных материалов, осознаете возмездный 
                    характер оказываемых услуг.
                </p>
            </div>
        </Modal>
    )
}

export default Offer
