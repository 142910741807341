import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { UserContext } from "../../store/user/UserContext";
import { links } from "./_paramitersLinks";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  trainingConfigs,
  UseTraining,
  UseTrainingConfigs,
} from "../../utils/training";
import Route from "../../hoc/route";
import { TrainingContext } from "../../store/training/TrainingContext";
import { useWindowSize } from "../../utils/useWindowSize";
import { ModalContext } from "../../store/modal/ModalContext";

const initialState = { isActive: false };

function reducer(state, action) {
  switch (action.type) {
    case "active":
      return { isActive: !state.isActive };
    case "setActive":
      return { isActive: true };
    case "hide":
      return { isActive: false };
    default:
      return state;
  }
}

function Parameters({ location }) {
  const { user, logout } = useContext(UserContext);
  const rootRef = useRef();
  const [{ isActive }, dispatch] = useReducer(reducer, initialState);
  const rootClasses = classNames("parameters", {
    [`parameters--${user.gender}`]: true,
    [`parameters__${location}`]: true,
  });
  const history = useHistory();
  const { isActive: trainingActive, currentStep } = useContext(TrainingContext);
  const browserLoc = useLocation();
  const { showModal } = useContext(ModalContext);

  const { register, setStepCallback, startTraining } = UseTraining();

  const toggleActive = useCallback(() => {
    dispatch({ type: "active" });
  }, []);

  const [width] = useWindowSize();

  useEffect(() => {
    if (width >= 650) {
      // setStepCallback({ stepIndex: 7, nextCb: () => toggleActive() })
      setStepCallback({
        stepIndex: 8,
        nextCb: () => history.push(Route("profile.change")),
      });
      setStepCallback({
        stepIndex: 9,
        prevCb: () => history.push(Route("profile.info")),
      });
    } else if (width !== 0) {
      // setStepCallback({ stepIndex: 10, nextCb: () => toggleActive() })
      setStepCallback({
        stepIndex: 11,
        prevCb: () => toggleActive(),
        nextCb: () => {
          history.push(Route("profile.change"));
          toggleActive();
        },
      });
      setStepCallback({
        stepIndex: 12,
        prevCb: () => history.push(Route("profile.info")),
      });
    }
  }, [width]);

  useEffect(() => {
    const hideList = (e) => {
      if (!e.path.includes(rootRef.current)) {
        !trainingActive && dispatch({ type: "hide" });
      }
    };

    document.addEventListener("click", hideList);

    return () => {
      document.removeEventListener("click", hideList);
    };
  }, [rootRef]);

  useEffect(() => {
    if (trainingActive) {
      if (width < 650 && currentStep === 11) {
        toggleActive();
      } else if (width >= 650 && currentStep === 8) {
        dispatch({ type: "setActive" });
      } else {
        dispatch({ type: "hide" });
      }
    }
  }, [trainingActive, currentStep, width]);

  const handleLogout = () => {
    logout();
  };

  const configs = UseTrainingConfigs();

  const registers = useMemo(() => {
    return [
      register(configs.paramiters[0], 1),
      register(configs.paramiters[1], 2),
    ];
  }, [width, location, browserLoc]);

  const currentLinks = links[location];

  const startTrain = () => {
    history.push(Route("channels.list"));
    startTraining();
  };

  return (
    <div className={rootClasses} ref={rootRef}>
      <div className="parameters__inner">
        <span
          className="parameters__open"
          ref={registers[0]}
          onClick={() => toggleActive()}
        >
          <span></span>
        </span>
        <div
          className={classNames("parameters__list", {
            "parameters__list--active": isActive,
          })}
        >
          <div className="parameters__list-item" onClick={startTrain}>
            Навигация
          </div>
          {currentLinks.map((item, index) =>
            !item.type ? (
              <Link
                ref={index === 0 ? registers[1] : null}
                className="parameters__list-item"
                key={index}
                onClick={() => toggleActive()}
                to={item.value}
              >
                {item.title}
              </Link>
            ) : (
              <a
                className="parameters__list-item"
                href={item.value}
                target="_blank"
              >
                {item.title}
              </a>
            )
          )}
          <div
            className="parameters__list-item"
            onClick={() => showModal("unsubscribe")}
          >
            Отписаться
          </div>
          {location === "header" && (
            <div className="parameters__list-item" onClick={handleLogout}>
              Выход
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Parameters);
