import classnames from 'classnames';
import moment from 'moment';
import { useEffect } from 'react';
import LazyLoad, { forceCheck } from 'react-lazyload';

import { Link } from 'react-router-dom';
import Route from '../../hoc/route';

export default function Message({ message, channelType, anchorRef, isSelected, toggleAnchor }) {

    useEffect(() => {
        forceCheck();
    }, [])

    const image = message.preview.img || '';
    const time = message.time;
    
    return (
        <div className={classnames('channel__messages-item', { 'channel__messages-item-selected': isSelected })} key={message.id} ref={anchorRef}>
            <div className='channel__messages-item-inner'>
                <div className={classnames({'channel__messages-item-avatar': true, [`channel__messages-item-avatar--${channelType}`]: true })} />
                <div className="channel__messages-item-content">
                        <div className='channel__messages-item-content-wrapp'>
                            <LazyLoad 
                                height={80} 
                                offset={500}
                                overflow 
                                scrollContainer={'channel__messages'}
                                style={{ minWidth: 80 }}>
                                <div className="channel__messages-item-image" style={{ backgroundImage: `url(${image})`}}></div>
                            </LazyLoad>
                            <div className="channel__messages-item-text">
                                <div className='channel__messages-item-text-title'>{message.preview.title}</div>
                                <div className='channel__messages-item-text-subtitle'>{message.preview.text}</div>
                            </div>
                            <div className="channel__messages-item-date">{moment.unix(time).format('HH:mm')}</div>
                        </div>
                    <Link to={Route('channels.article', channelType, message.preview.id)} className='channel__messages-item-link'>Читать далее</Link>
                    <span className="channel__messages-item-anchor" onClick={toggleAnchor}>{anchorRef ? 'открепить' : 'закрепить' }</span> 
                </div>
            </div>
        </div>
        
    )
}