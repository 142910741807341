import { getConfigUrls } from './fetch';

const localStorage = global.window.localStorage;
const token = 'config';

export const configs = {
  getToken: () => JSON.parse(localStorage.getItem(token)),
  setToken: data => localStorage.setItem(token, JSON.stringify(data)),

  initialize: () =>
    getConfigUrls().then(response => {
      Object.keys(response).map(key => {
        if (String(response[key]).slice(-1) === '/') {
          return response[key] = String(response[key]).substring(0, response[key].length -1);
        }
        return response[key];
      });
      configs.setToken(response);
    }),
};