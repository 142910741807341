import maleAvatar from '../img/male/default-profile.svg'
import femaleAvatar from '../img/female/default-profile.svg'

export function prepareBkLink(url, user){
    return url.replace('%s', !!user ? user.id : '' ) + '&' + new URLSearchParams({type: 'bk'});
}

export function translit(word){
	let answer = '';
	let converter = {
		'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
		'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
		'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
		'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
		'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
		'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
		'э': 'e',    'ю': 'yu',   'я': 'ya',
 
		'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
		'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
		'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
		'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
		'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
		'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
		'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya'
	};
 
	for (let i = 0; i < word.length; ++i ) {
		if (converter[word[i]] == undefined){
			answer += word[i];
		} else {
			answer += converter[word[i]];
		}
	}
 
	return answer;
}

export const prepareUserData = (user) => {
	const defaultUserAvatar = {
		male: maleAvatar,
		female: femaleAvatar
	}
	
	return {
		...user,
		avatar: user?.avatar || defaultUserAvatar[user?.gender],
		question: {
			...user?.question,
			weight: user.question?.parameters ? user.question?.parameters[1] : 0,
			height: user.question?.parameters ? user.question?.parameters[0] : 0,
		},
		first_name: user.first_name.replace(/#\d+/, ''),
		fullName: user?.first_name + ' ' + user?.last_name
	}
}