import { SET_TABBAR_BUTTON, SET_HEADER_MOBILE, HIDE_FOOTER_MOBILE, FIXED_HEADER_MOBILE, HIDE_TABBAR_MOBILE, SHOW_PROFILE_HEADER } from '../types';

import React, { useReducer } from 'react';
import { AppContext } from './AppContext';
import { appReducer } from './AppReducer';

export const AppState = ({children}) => {

    const initialState = {
        tabbarButton: '',
        headerTitle: '',
        headerBack: '',
        hideFooterMobile: false,
        fixedHeaderMobile: false,
        hideTabbarMobile: false,
        headerBackParams: [],
        showProfileHeader: false,
        profileHeaderTitle: '',
        showProfileHeaderButton: false
    }

    const [state, dispatch] = useReducer(appReducer, initialState)

    const setTabbarButton = button => {
        dispatch({
            type: SET_TABBAR_BUTTON,
            button
        })
    }

    const setHeaderMobile = (headerTitle, headerBack, headerBackParams = []) => {
        dispatch({
            type: SET_HEADER_MOBILE,
            headerTitle,
            headerBack,
            headerBackParams
        })
    }

    const setHideTabbarMobile = () => {dispatch({type: HIDE_TABBAR_MOBILE})}
    const setHideFooterMobile = () => dispatch({type: HIDE_FOOTER_MOBILE});
    const setFixedHeaderMobile = (fixedHeaderMobile) => dispatch({type: FIXED_HEADER_MOBILE, fixedHeaderMobile});
    const setShowProfileHeader = (value, title, isShowButton) => {
        dispatch({type: SHOW_PROFILE_HEADER, value, title, isShowButton}) 
    }

    return (
        <AppContext.Provider value={{
            setTabbarButton,
            setHeaderMobile,
            setHideTabbarMobile,
            setHideFooterMobile,
            setFixedHeaderMobile,
            setShowProfileHeader,
            ...state
        }}>
            {children}
        </AppContext.Provider>
    )
}