import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const redirect = (path) => {
    const location = {
      pathname: path,
      state: { fromDashboard: true }
    };
    if (path !== '/' && path === window.location.pathname) return;
    history.push(location);
};

export  {history, redirect};