import React, { useReducer } from 'react';
import { configs } from '../../utils/config';
import { getToken } from '../../utils/getToken';
import { prepareMessage } from '../../utils/message';
import { DIALOGS_PENDING, DIALOG_GET, DIALOG_EMPTY, DIALOG_HIDE_MESSAGE, DIALOG_DELETE_MESSAGE } from '../types';
import {DialogContext} from './DialogContext';
import { dialogReducer } from './DialogReducer';
import { getApi } from '../../utils/api'
import { translit } from '../../utils/modifiers'

let socket = null;
export const DialogState = ({children}) => {

    const api = getApi()
    let config = configs.getToken();
    let token = getToken();

    const initialState = {
        chatId: null,
        messages: [],
        data: {},
        open: false,
        text: '',
        count: null,
        scrollDown: true,
        scroll: {
            pos: 0,
            full: 0
        },
        image: '',
        typing: false,
        fullClip: false,
        unread: 0,
        error: false,
        pending: false,
        userInfoIsOpen: false,
        user: {
            "loaded": null,
            "id": null,
            "type": null,
            "external_id": null,
            "first_name": null,
            "last_name": null,
            "username": null,
            "phone": null,
            "language_code": null,
            "avatar": null,
            "referred_by": null,
            "referal_count": null,
            "linked_with": null,
            "password": null,
            "token": null,
            "balance": null,
            "spent_sum": null,
            "bank": null,
            "messages_counter": null,
            "to_admin_messages_counter": null,
            "roles": [],
            "params": {},
            "deeplinks": {},
            "push_tokens": [],
            "created_at": null,
            "updated_at": null,
            "deleted_at": null,
            "mode": null,
            "cp_token": null,
            "chats": [],
            "categories": [],
            "linked_users": [],
            "bookmakers": [],
            "bookmakers_revshare_sum": []
        },
    };
    
    const [state, dispatch] = useReducer(dialogReducer, initialState)

    const onMessage = (chatId, event) => {
        let payload = JSON.parse(event.data);
        if(payload.messages) {
            payload.messages.forEach(message => {
                return message = prepareMessage(message)
            });
            // pay
            let data = payload.chats && payload.chats.find(chat => chat.id === parseInt(chatId));
            dispatch({type: DIALOG_GET, messages: payload.messages.filter(item => item.chat_id ? chatId === parseInt(item.chat_id) : item), data});
        }
            
        if(payload.command?.action === 'hide')
            dispatch({ type: DIALOG_DELETE_MESSAGE, messageId: payload.command.message_id })
    }

    const onClose = store => (event) => {
        dispatch({type: DIALOG_EMPTY, initialState})
    }

    const onOpen = store => (event) => {
        // dispatch({type: DIALOGS_PENDING})
    }

    const connectToSocket = (chatId) => {
        if (socket) socket.close();

        // connect to the remote host
        socket = new WebSocket(config.ws + `?token=${token}&chat_id=${chatId}`);
        // console.log('chatId', chatId);
        // websocket handlers
        socket.onmessage = (event) => onMessage(chatId, event);
        socket.onclose = onClose(123);
        socket.onopen = onOpen(123);
    }

    const sendMessage = async (message) => {
        let imageUrl;
        if(message.file[0])
            imageUrl = await upload(message.file[0], message.file[0].name)
        message.image = imageUrl
        message.text = message.text.trim();
        socket.send(JSON.stringify(message));
    }

    const upload = async (file, name) => {
        const filename = translit(name)
        const responce = await api.post('upload', file, { headers: { 'Content-Type': 'multipart/form-data', filename }})
        return 'https://giveaways.roimaster.site/' + responce.data.file
    }

    const disconnectSocket = () => {
        socket && socket.close();
    }

    return (
        <DialogContext.Provider value={{
            ...state,
            sendMessage,
            connectToSocket,
            upload,
            disconnectSocket
        }}>
            {children}
        </DialogContext.Provider>
    )
}
