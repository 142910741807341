import React, { useContext } from 'react'
import Button from '../UI/Button'
import Modal from '../UI/Modal'
import { ModalContext } from '../store/modal/ModalContext'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SuccessUnsubscribe({ theme }) {
    const { hideModal } = useContext(ModalContext)

    const iconColor = theme === 'male' ? '#3b7ac3' : '#f99494'

    return (
        <Modal id="successUnsubscribe" className='unsubscribe'> 
            <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 77, margin: '0 auto 30px auto', display: 'block', color: iconColor}} />
            <div className="modal__dialog-text">
                Платёжная информация удалена
            </div>
            <div className='unsubscribe__buttons'>
                <Button type="button" color="main" onClick={() => hideModal('successUnsubscribe')}>Ок</Button>
            </div>
        </Modal>
    )
}

export default SuccessUnsubscribe
