import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/svg/logo.svg'
import { AppContext } from '../../store/app/AppContext';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../store/user/UserContext';
import route from '../../hoc/route'
import { ModalContext } from '../../store/modal/ModalContext';


export default function Footer() {
    const {hideFooterMobile} = useContext(AppContext);
    const { user } = useContext(UserContext);
    const { showModal } = useContext(ModalContext)

    return (
        <footer className={classnames("footer", { "footer--mobile-hide": hideFooterMobile, [`footer--${user.gender}`]: true })}>
        <div className="footer__container container">
            <div className="footer__row row">
                <div className="col-lg-4 footer__col">
                    <Link rel="stylesheet" to="/">
                        <img src={logo} alt="logo"/>
                    </Link>
                    <div className="footer__col-text">Достигни своего идеала – начни правильно питаться и тренироваться вместе с Cheat Meal! Обрети тело своей мечты и измени жизнь к лучшему!</div>
                    <div style={{flex:'1'}}/>
                    <p>Все права защищены, © 2021 г.</p>
                </div>
                <div className="col-lg-4 footer__col">
                    <div className="footer__menu">
                        {user.id && <a className="footer__menu-link" onClick={() => showModal('unsubscribe')}>Отписаться</a>}
                        <Link className="footer__menu-link" to={route('channels.list')}>Каналы</Link>
                        <Link className="footer__menu-link" to={route('support')}>Поддержка</Link>
                        <Link className="footer__menu-link" to={route('profile')}>Профиль</Link>
                        <Link className="footer__menu-link" to="/">Прогресс</Link>
                    <div style={{flex:'1'}} />
                        <Link className="footer__menu-link" to={route('politics')}>Политика конфиденциальности</Link>
                    <div className="footer__menu">
                        {/* <Link className="footer__menu-link" to="/">Вопросы и ответы</Link> */}
                        {/* <Link className="footer__menu-link" to="/broadcasts">Условия использования</Link> */}
                    </div>
                    <div className="footer__contact">
                        {/* <div className="footer__contact-title">Почта:</div> */}
                        <div className="footer__contact-body">
                            <div className="footer__contact-body-info">
                                <FontAwesomeIcon style={{ color: 'red' }} icon={faEnvelope}/>
                                <a href="mailto:help@cheatmeal.pro">help@cheatmeal.pro</a>
                            </div>
                            <div>
                                <FontAwesomeIcon style={{ color: 'red'}} icon={faPhone}/>
                                <a href="tel:+79161789549">+79161789549</a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="footer__social">
                        <a href="/" className="footer__social-link" style={{ background: 'red' }}><FontAwesomeIcon icon={faInstagram} /></a>
                        <a href="/" className="footer__social-link" style={{ background: 'red' }}><FontAwesomeIcon icon={faVk} /></a>
                        <a href="/" className="footer__social-link" style={{ background: 'red' }}><FontAwesomeIcon icon={faTwitter} /></a>
                    </div> */}
                    </div>
                </div>
                <div className="col-lg-4 footer__col">
                    <div className='footer__info'>
                        <span className='footer__info-item'>
                            ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ ЗУЗУЛИН РОМАН АЛЕКСЕЕВИЧ
                        </span>
                        <span className='footer__info-item'>
                            ИНН: 237306578484
                            ОГРНИП: 320237500021151
                            КРАСНОДАРСКИЙ КРАЙ, ДИНСКОЙ Р-Н, П ЮЖНЫЙ, УЛ МИРА, дом 96
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}
