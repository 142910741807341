import route from '../../hoc/route'

export const links = {
    header: [
        {
            title: 'Редактировать данные',
            value: route('profile.change')
        },
        {
            title: 'Тарифы',
            value: route('politics')
        },
        // {
        //     title: 'Отписаться',
        //     value: route('support')
        // },
        {
            title: 'Политика конфиденциальности',
            value: route('politics')
        },
        {
            title: 'Вопросы и ответы',
            value: 'https://cheatmeal.pro/faq',
            type: 'url'
        },
        {
            title: 'Условия использования',
            value: route('politics')
        }
    ],
    profile: [
        {
            title: 'Редактировать данные',
            value: route('profile.change')
        },
        {
            title: 'Тарифы',
            value: '/'
        },
        // {
        //     title: 'Отписаться',
        //     value: '/'
        // },
    ]
}
