import { Redirect, useParams } from "react-router";
import queryString from "query-string";
import Route from "../hoc/route";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../store/user/UserContext";
import { useContext } from "react";

export const Auth = (props) => {
  const { firstPayFetch, firstPayLoading } = useContext(UserContext);
  const { token } = useParams();
  const history = useHistory();
  const { first_step } = queryString.parse(document.location.search);

  useEffect(() => {
    // window.dataLayer.push({ event: "paid" });
    // const tagManagerArgs = {
    //     gtmId: 'GTM-KSHSB3S',
    //     events:{
    //         paid:"paid"
    //     }
    // }
    // TagManager.dataLayer(tagManagerArgs);
    console.log("before checking");
    if(token) {
      localStorage.setItem("token", token);
    }
    firstPayFetch(token);
  }, []);
  
  return token ? <Redirect to={Route("channels")} /> : <></>;
};
