import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../store/app/AppContext';
import { ModalContext } from '../../store/modal/ModalContext';
import { UserContext } from '../../store/user/UserContext'
import maleAvatar from '../../img/male/default-profile.svg'
import Parameters from '../../components/Parameters/Parameters';
import Button from '../../UI/Button';
import Control from '../../UI/Control'
import { useForm } from 'react-hook-form';
import WrongTypeFile from '../../modals/WrongTypeFile';
import { trainingConfigs, UseTraining, UseTrainingConfigs } from '../../utils/training';
import { useWindowSize } from '../../utils/useWindowSize';

function ProfileChange(props) {

    const {
        setShowProfileHeader,
    } = useContext(AppContext)
    const [width] = useWindowSize()

    const { showModal } = useContext(ModalContext)
    const { user, changePassFetch, loading, errors, updateUser } = useContext(UserContext)
    const [photo, setPhoto] = useState(user.avatar)
    const { handleSubmit, register, errors: formErrors }  = useForm();

    const onSubmit = (data) => {
        if(data.password && data.passwordConfirm) 
            changePassFetch({ password: data.password, passwordConfirm: data.passwordConfirm })

        if(user.first_name !== data.firstName || user.last_name !== data.lastName)
            updateUser({ first_name: data.firstName, last_name: data.lastName, id: user.id })
    }

    useEffect(() => {
        setShowProfileHeader(true, 'Редактировать профиль', true)
    }, []);

    
    const handleChangePhoto = (e) => {
        const file = e.target.files[0]
        if(!e.target.files[0]) return
        if(file.type.split('/')[0] !== 'image') {
            showModal('wrongType');
            e.target.value = '';
            return;
        }
        setPhoto(URL.createObjectURL(file))
    }

    const { register: trainingRegister, setStepCallback } = UseTraining();
    const configs = UseTrainingConfigs();

    const registers = useMemo(() => {
        return [
            trainingRegister(configs.profileChange[0], 1),
            trainingRegister(configs.profileChange[0], 2),
            trainingRegister(configs.profileChange[0], 3),
            trainingRegister(configs.profileChange[0], 4),
            trainingRegister(configs.profileChange[4], 5),
        ]
    }, [width]);
    
    return (
        <div className='profile__change'>
                <div className="profile__header">
                    <div className="profile__info">
                        <label className='profile__info-avatar' style={{ backgroundImage: `url(${photo})`}}>
                            <input type="file" onChange={handleChangePhoto}/>
                        </label>
                        <div className='profile__info-name'>
                            {user.email}
                        </div>
                    </div>
                    <Parameters location='profile' />
                </div>
            <div className='profile__change-inner'>
                <h1 className='profile__change-title' ref={registers[4]}>Редактировать данные</h1>
                <form className="profile__change-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='profile__change-form-inputs'>
                        <div ref={registers[0]}>
                            <Control 
                                ref={register()} 
                                defaultValue={user.first_name} 
                                as='input' 
                                placeholder='Имя'
                                type='text'
                                name='firstName' 
                                error={formErrors?.firstName?.message} />
                        </div>
                        <div ref={registers[1]}>
                            <Control 
                                ref={register()} 
                                defaultValue={user.last_name} 
                                as='input' 
                                placeholder='Фамилия'
                                type='text'
                                name='lastName' 
                                error={formErrors?.lasName?.message} />
                        </div>
                        <div ref={registers[2]}>
                            <Control 
                                ref={register({})}
                                as='input' 
                                placeholder='Пароль' 
                                type='password'
                                name='password' />
                        </div>
                        <div ref={registers[3]}>
                            <Control 
                                ref={register({})}
                                as='input' 
                                placeholder='Повторите пароль' 
                                type='password'
                                name='passwordConfirm'
                                error={errors.forgotPass} />
                        </div>

                    </div>
                    <Button className='profile__change-button' loading={loading} size='large' color='main'>Сохранить</Button>
                </form>
            </div>
            {/* <WrongTypeFile text={'Выберите картинку'} /> */}
        </div>
    )
}

export default ProfileChange
