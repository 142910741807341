import { useCallback, useContext } from 'react';
import { TrainingContext } from '../store/training/TrainingContext';
import { useWindowSize } from './useWindowSize';

export const getStorageActive = () => JSON.parse(localStorage.getItem('training')) == null;

export const UseTraining = () => {
  const { setNewStep, setStepCallback: setCallback, startTraining } = useContext(TrainingContext);

  const register = useCallback(({ step, configs, last }, id) => {
    return (ref) => {
      if(!step && step !== 0 || !ref) return;
      setNewStep({ stepIndex: step, stepConfigs: configs, ref, id, last: last ?? false });
    };
  }, []);

  const setStepCallback = useCallback(({ stepIndex, nextCb, prevCb }) => {
    setCallback({ stepIndex, nextCb, prevCb })
  }, [])

  return {
    register,
    setStepCallback,
    startTraining
  };
};

export const isStepsEqual = (currentStep, newStep, id) => {
  return currentStep.elements[newStep.type]?.find((elementItem) => elementItem.id === id)
}

export const UseTrainingConfigs = () => {
  const [width] = useWindowSize();

  return { 
  tabbar: [
    {
      step: 7,
      configs: [
        { type: 'pointer', styles: { left: '70%', top: '-10%', transform: 'rotate(-120deg)' }},
        {
          type: 'text',
          text: `Ваш профиль, где вы можете изменить ваши показатели и посмотреть вашу суточную норму ккал`,
          styles: { left: '-40px', top: '-180px', color: '#fff', minWidth: 200 },
        },
      ],
    },
    {
      step: 13,
      configs: [
        { type: 'pointer', styles: { left: '50%', top: '-10%', transform: 'rotate(-120deg)' } },
        {
          type: 'text',
          text: `Прогресс, где вы можете отмечать колличество потраченных и потребляемых ккал в день, а так же отслеживать сколько вам ещё необходимо`,
          styles: { left: '-140px', top: '-200px', color: '#fff', minWidth: 200 },
        },
      ],
    }
  ],
  header: [
    {
      step: 4,
      configs: [
        { type: 'pointer', styles: { left: '-45%', top: '30%', transform: 'rotate(45deg)' } },
        {
          type: 'text',
          text: `Ваш профиль, где вы можете изменить ваши показатели и посмотреть вашу суточную норму ккал`,
          styles: { left: '20%', top: '130px', color: '#fff', minWidth: 200 },
        },
      ],
    },
    {
      step: 10,
      configs: [
        { type: 'pointer', styles: { left: '-45%', top: '30%', transform: 'rotate(45deg)' } },
        {
          type: 'text',
          text: `Прогресс, где вы можете отмечать колличество потраченных и потребляемых ккал в день, а так же отслеживать сколько вам ещё необходимо`,
          styles: { left: '20%', top: '130px', color: '#fff', minWidth: 200 },
        },
      ],
    }
  ],
  paramiters: [
    {
      step: width !== 0 && width <= 650 ? 10 : 7,
      configs: [
        { type: 'pointer', styles: { left: '-40px', top: '30%', transform: 'rotate(45deg)' } },
        {
          type: 'text',
          text: `Нажмите сюда, чтобы отредактировать
          данные входа, посмотреть тарифы, отписаться от тарифа, прочитать политику конфидициальности, условия использования, часто задаваемые вопросы и ответы, а так же выйти из своего личного кабинета`,
          styles: { left: '-200px', top: '60px', color: '#fff', minWidth: 200 },
        },
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 11 : 8,
      configs: [
        { type: 'pointer', styles: { left: '-5%', top: '30%', transform: 'rotate(45deg)' } },
        {
          type: 'text',
          text: `Нажмите, чтобы изменить e-mail или
          пароль`,
          styles: { left: '20%', top: '155px', color: '#fff', minWidth: 200 },
        },
      ],
    }
  ],
  channels: [
    {
      step: 0,
      configs: [
        { type: 'pointer', styles: { left: '50%', top: '70%', transform: 'translateX(-50%)' } },
        {
          type: 'text',
          text: `Ежедневно просматривайте этот канал 
          с ежедневными рецептами блюд, 
          правильно рассчитанных по 
          калорийности и балансу БЖУ`,
          styles: { left: '30%', top: '-60px', color: '#fff', minWidth: 200 },
        },
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 2 : 1,
      configs: [
        { type: 'pointer', styles: { left: '50%', top: '70%', transform: 'translateX(-50%)' } },
        {
          type: 'text',
          text: `На этом канале мы публикуем разные 
          варианты тренировок, которые сможет 
          выполнять даже не тренированный 
          человек.`,
          styles: { left: '30%', top: '-60px', color: '#fff', minWidth: 200 },
        },
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 4 : 2,
      configs: [
        { type: 'pointer', styles: { left: '50%', top: '70%', transform: 'translateX(-50%)' } },
        {
          type: 'text',
          text: `В этом канале мы публикуем  информацию о правилах питания  и ведения здорового образа жизни.  Следуйте им и Вы будете иметь не 
          только красивую фигуру,  но и сильный иммунитет, здоровый организм и красивую кожу.`,
          styles: { left: '30%', top: '-130px', color: '#fff', minWidth: 200 },
        },
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 6 : 3,
      configs: [
        { type: 'arrow', styles: { top: '0px', left: '-25px', transform: 'rotate(-125deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 6 : 3,
      configs: [
        { type: 'text', styles: { top: '20%', left: '10%' }, text: `Так мы уведомляем вас о новых
        публикациях на каналах` }
      ],
    },
  ],
  support: [
    {
      step: width !== 0 && width <= 650 ? 8 : 5,
      configs: [
        { type: 'pointer', styles: { left: '50%', top: '-70%', transform: 'rotate(-175deg)' }, noBlock: true },
        {
          type: 'text',
          text: `введите ваш вопрос`,
          styles: { left: '70%', top: '-170px', color: '#fff', minWidth: 200 },
        }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 9 : 6,
      configs: [
        { type: 'pointer', styles: { left: '-40px', top: '-40px', transform: 'rotate(-205deg)' }, noBlock: true },
        {
          type: 'text',
          text: `к вашему вопросу, вы можете прикрепить фотографию`,
          styles: { left: '-180px', top: '-180px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 10 : 7,
      configs: [
        { type: 'pointer', styles: { left: '0px', top: '-30px', transform: 'rotate(-180deg)' } },
        {
          type: 'text',
          text: `Отправьте сообщение`,
          styles: { left: '-180px', top: '-170px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
    },
  ],
  profile: [
    {
      step: width !== 0 && width <= 650 ? 8 : 5,
      configs: [
        { type: 'arrow', styles: { top: '50%', left: 'calc(90% - 25px)', transform: 'rotate(180deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 8 : 5,
      configs: [
        { type: 'arrow', styles: { top: '50%', left: 'calc(90% - 25px)', transform: 'rotate(180deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 8 : 5,
      configs: [
        { type: 'arrow', styles: { top: '50%', left: 'calc(90% - 25px)', transform: 'rotate(180deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 8 : 5,
      configs: [
        { type: 'arrow', styles: { top: '50%', left: 'calc(90% - 25px)', transform: 'rotate(180deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 8 : 5,
      configs: [
        { type: 'arrow', styles: { top: '50%', left: 'calc(90% - 25px)', transform: 'rotate(180deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 8 : 5,
      configs: [
        {
          type: 'text',
          text: `Вы можете отредактировать свои показатели на верные, если у вас что-то изменилось`,
          styles: { left: '40px', top: '-100px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 9 : 6,
      configs: [
        { type: 'arrow', styles: { top: '-20px', left: '90%', transform: 'rotate(-60deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 9 : 6,
      configs: [
        {
          type: 'text',
          text: `Тут вы можете отслеживать вашу норму
          потребления суточных ккал`,
          styles: { left: '40px', top: '-100px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
    },
  ],
  profileChange: [
    {
      step: width !== 0 && width <= 650 ? 12 : 9,
      configs: [
        { type: 'arrow', styles: { top: '50%', left: 'calc(80% - 25px)', transform: 'rotate(180deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 12 : 9,
      configs: [
        { type: 'arrow', styles: { top: '50%', left: 'calc(80% - 25px)', transform: 'rotate(180deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 12 : 9,
      configs: [
        { type: 'arrow', styles: { top: '50%', left: 'calc(80% - 25px)', transform: 'rotate(180deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 12 : 9,
      configs: [
        { type: 'arrow', styles: { top: '50%', left: 'calc(80% - 25px)', transform: 'rotate(180deg)' } }
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 12 : 9,
      configs: [
        {
          type: 'text',
          text: `При необходимости вы всегда можете изменить свои данные`,
          styles: { left: '25%', top: '-56px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
    },
  ],
  progress: [
    {
      step: width !== 0 && width <= 650 ? 14 : 11,
      configs: [
        { type: 'pointer', styles: { left: '50%', top: '-50%', transform: 'rotate(-140deg)' } },
        {
          type: 'text',
          text: `Вы можете ввести сколько ккал вы употребили
          за прием пищи, программа подсчитает сколько вам нужно ещё употребить ккал за сутки`,
          styles: { left: '0%', top: '-140px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 15 : 12,
      configs: [
        { type: 'pointer', styles: { left: '20%', top: '-50px', transform: 'rotate(-180deg)' } },
        {
          type: 'text',
          text: `Вы можете переключаться между днями, чтобы дополнить или 
          исправить введенные вами данные`,
          styles: { left: '100px', top: '-100px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
    },
    {
      step: width !== 0 && width <= 650 ? 16 : 13,
      configs: [
        {
          type: 'text',
          text: `Отлеживайте сколько ккал вы сожгли
          на этой неделе и в этом месяце`,
          styles: { left: '0', top: '-80px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
      last: true
    },
    {
      step: width !== 0 && width <= 650 ? 16 : 13,
      configs: [
        { type: 'arrow', styles: { top: '-15px', left: '10%', transform: 'rotate(-130deg)' } }
      ],
      last: true
    },
    {
      step: width !== 0 && width <= 650 ? 16 : 13,
      configs: [
        { type: 'arrow', styles: { top: '-15px', left: '10%', transform: 'rotate(-130deg)' } }
      ],
      last: true
    },
  ],
  fixedHeader: [
    {
      step: 1,
      configs: [
        { type: 'pointer', styles: { left: '0%', top: '50px' } },
        {
          type: 'text',
          text: `Вернитесь к списку каналов`,
          styles: { left: '10%', top: '140px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
    },
    {
      step: 3,
      configs: [
        { type: 'pointer', styles: { left: '0%', top: '50px' } },
        {
          type: 'text',
          text: `Вернитесь к списку каналов`,
          styles: { left: '10%', top: '140px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
    },
    {
      step: 5,
      configs: [
        { type: 'pointer', styles: { left: '0%', top: '50px' } },
        {
          type: 'text',
          text: `Вернитесь к списку каналов`,
          styles: { left: '10%', top: '140px', color: '#fff', minWidth: 200, textTransform: 'none' },
        },
      ],
    }
  ]}
};
