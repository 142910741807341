import {
  USER_FETCH_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_READY,
  USER_UPDATE_SUCCESS,
  USER_UPDATE,
  USER_FETCH_SUCCESS,
  USER_FETCH_ERROR,
  USER_FETCH_INIT,
  USER_UPDATE_CALORIES,
  UPDATE_USER,
  USER_FIRST_PAY_LOADING,
  USER_FIRST_PAY_SUCCESS,
  USER_FIRST_PAY_ERROR,
} from "../types";

import React, { useReducer } from "react";
import { UserContext } from "./UserContext";
import { userReducer } from "./UserReducer";
import { getApi } from "../../utils/api";
import { configs } from "../../utils/config";
import { prepareUserData } from "../../utils/modifiers";
import {history} from '../../hoc/history'

export const UserState = ({ children }) => {
  const api = getApi();
  const token = localStorage.getItem("token");

  const initialState = {
    name: "",
    ready: false,
    phone: "",
    user: {
      id: "",
      external_id: "",
      first_name: "",
      last_name: "",
      avatar: null,
      username: "",
      email: "",
      token: configs.getToken(),
      balance: 0,
      mode: 0,
      roles: [],
      params: {},
      // instagram: '',
    },
    pending: null,
    isAuthorized: false,
    errors: {
      login: "",
      register: "",
      joinSponsor: "",
      forgotPass: "",
    },
    loading: false,
    success: false,
    loader: 0,
    interval: null,
    deeplinks: {
      ip: "",
    },
    parameters: {},
    push_tokens: {},
    gender: "male",
    firstPayLoading: false,
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  const initUser = async () => {
    dispatch({ type: USER_FETCH_INIT });
    let response = await api
      .post("/token")
      .catch(() => dispatch({ type: USER_READY }));
    if (response) {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        token,
        user: prepareUserData(response.data),
      });
    }
  };

  const fetchLogin = async ({ email, password }) => {
    dispatch({ type: USER_FETCH_LOGIN });
    let response = await api
      .post("/auth", { email, password })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        dispatch({
          type: USER_LOGIN_SUCCESS,
          token,
          user: prepareUserData(response.data),
        });
      })
      .catch((error) => {
        dispatch({ type: USER_FETCH_ERROR, login: "Неверный пароль" });
      });
  };

  const fetchRegister = async ({ email, firstName, lastName, password }) => {
    dispatch({ type: USER_FETCH_LOGIN });
    let response = await api
      .post("/register", { email, firstName, lastName, password })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        dispatch({
          type: USER_LOGIN_SUCCESS,
          token,
          user: prepareUserData(response.data.user),
        });
        return true;
      })
      .catch((error) => {
        dispatch({ type: USER_FETCH_ERROR, register: "Почта уже занята" });
      });
  };

  const logout = () => {
    dispatch({ type: USER_LOGOUT });
    localStorage.removeItem("token");
  };

  const updateUser = async (data) => {
    dispatch({
      type: USER_UPDATE,
      data: { first_name: data.first_name, last_name: data.last_name },
    });
    let response = await api
      .patch("/user", {
        first_name: data.first_name,
        last_name: data.last_name,
      })
      .then((response) => {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          data: prepareUserData(response.data[0]),
        });
      });
  };

  const updateUserQuestion = async (data) => {
    dispatch({ type: USER_UPDATE, data });
    let response = await api.patch("/user", { ...data }).then((response) => {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        data: prepareUserData(response.data[0]),
      });
    });
  };

  const changePassFetch = ({ password, passwordConfirm }) => {
    dispatch({ type: USER_FETCH_LOGIN });
    let responce = api
      .post("/change-password", { password, passwordConfirm })
      .then((responce) => {
        dispatch({ type: USER_FETCH_SUCCESS });
      })
      .catch((responce) => {
        dispatch({
          type: USER_FETCH_ERROR,
          forgotPass: "Пароль должны совпадать",
        });
      });
  };

  const forgotPassFetch = async (email) => {
    dispatch({ type: USER_FETCH_LOGIN });
    let responce = await api
      .post("/forgot-password", { email })
      .then((responce) => {
        dispatch({ type: USER_FETCH_SUCCESS });
      })
      .catch((responce) => {
        dispatch({
          type: USER_FETCH_ERROR,
          forgotPass: "Пользователь с таким email не найден",
        });
      });
  };

  const updateCalories = async (newCalories) => {
    dispatch({ type: USER_UPDATE_CALORIES, newCalories });
  };

  const updateUserCalories = (data) => {
    dispatch({ type: UPDATE_USER, data });
  };

  const getUserCalories = async () => {
    try {
      let response = await api.post("/token");
      dispatch({ type: UPDATE_USER, data: response.data.calories });
    } catch (e) {
      dispatch({ type: USER_FETCH_ERROR });
    }
  };

  const userPay = (cost) => {
    api
      .get(`/pay?value=${cost}`)
      .then((response) => {
        window.open(response.data.confirmation.confirmation_url);
      })
      .catch((e) => dispatch({ type: USER_FETCH_ERROR }));
  };

  const unsubscribeFetch = async () => {
    try {
      await api.post("/pay/cancel");
    } catch (e) {
      console.log(e);
    }
  };

  const firstPayFetch = async (currentToken) => {
    try {
      dispatch({ type: USER_FIRST_PAY_LOADING });
      console.log("sending request");
      const { data } = await api.post(
        "/token",
        {},
        { headers: { Authorization: currentToken } }
      );
      if (data.first_pay && !data.view_first_pay) {
        window.ym && window.ym(87308821, "reachGoal", "paid");
        window.ym && window.ym(87308821, 'reachGoal', 'tria');
        await api.post(
          "/pay/view",
          {},
          { headers: { Authorization: currentToken } }
        );
      }

      dispatch({ type: USER_FIRST_PAY_SUCCESS });
      localStorage.setItem("token", currentToken);
      history.push('/')
    } catch (e) {
      console.log(e);
      dispatch({ type: USER_FIRST_PAY_ERROR });
    }
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        fetchLogin,
        logout,
        fetchRegister,
        initUser,
        updateUser,
        changePassFetch,
        updateUserQuestion,
        forgotPassFetch,
        updateCalories,
        updateUserCalories,
        getUserCalories,
        userPay,
        unsubscribeFetch,
        firstPayFetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
