import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AppContext } from '../store/app/AppContext';
import { UserContext } from '../store/user/UserContext';
import classNames from 'classnames';
import { ProgressContext } from '../store/progress/ProgressContext';
import moment from 'moment'
import Day from '../components/Progress/Day';
import { UseTraining, UseTrainingConfigs } from '../utils/training';
import { useWindowSize } from '../utils/useWindowSize';

function Progress() {

    const {
        hideFooterMobile,
        setHideFooterMobile
    } = useContext(AppContext)

    const [width] = useWindowSize()
    const { user } = useContext(UserContext)
    const { getActivities, setNewDay, currentDayTime } = useContext(ProgressContext)

    useEffect(() => {
        getActivities();
    }, [])

    useEffect(() => {
        if(!hideFooterMobile) setHideFooterMobile();
    });

    const setPrevDay = () => setNewDay(moment.unix(currentDayTime).subtract(1, 'days').unix())

    const setNextDay = () => setNewDay(moment.unix(currentDayTime).add(1, 'days').unix())

    const isToday = () => !moment.unix(currentDayTime).isSame(moment(), "day");

    const { register, setStepCallback } = UseTraining();
    const configs = UseTrainingConfigs()

    const registers = useMemo(() => {
        return [
            register(configs.progress[1], 1),
            register(configs.progress[2], 2),
            register(configs.progress[3], 3),
            register(configs.progress[4], 4),
        ]
    }, [width]);

    return (
        <div className={classNames('page', { [`page--${user.gender}`]: true })}>
            <div className='page__header'>
                <div className='page__title'>Прогресс</div>
            </div>
            <div className={classNames('progress', { [`progress--${user.gender}`]: true })}>
                <div className="progress__inner">
                    <div className="progress__results">
                        <div className="progress__results-item">
                            <div className="progress__results-item-title">
                                В этом месяце Вы {user.calories.left_month < 0 ? 'набрали' : 'сожгли'} 
                            </div>
                            <div className="progress__value progress__results-item-value" ref={registers[2]}>
                                {Math.trunc(Math.abs(user.calories.left_month))} ккал / {Math.trunc(Math.abs(user.calories.left_month * 0.142))} г
                            </div>
                        </div>
                        <div className="progress__results-item">
                            <div className=" progress__results-item-title">
                                За последние 7 дней Вы {user.calories.left_week < 0 ? 'набрали' : 'сожгли'} 
                            </div>
                            <div className="progress__value progress__results-item-value" ref={registers[3]}>
                            {Math.trunc(Math.abs(user.calories.left_week))} ккал / {Math.trunc(Math.abs(user.calories.left_week * 0.142))} г
                            </div>
                        </div>
                    </div>
                    <div className="progress__date">
                        <div className="progress__date-inner">
                            <span className={classNames("progress__date-back")} ref={registers[0]} onClick={setPrevDay}>вчера</span>
                            <span className="progress__date-text" ref={registers[1]}>{!isToday() && 'СЕГОДНЯ'} {currentDayTime && moment.unix(currentDayTime).format('DD.MM.YYYY')}</span>
                            <span className={classNames("progress__date-next", { 'progress__date-next--active': isToday() })} onClick={setNextDay}>завтра</span>
                        </div>
                    </div>
                    <Day user={user} />
                </div>
            </div>
        </div>
    )
}

export default Progress
