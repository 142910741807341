const MainLoader = () => (
    <div className="main-loader">
        <div className="main-loader__wrapper">
            <div className="main-loader__wrapper-logo">
                <span className="main-loader__wrapper-logo">C</span>
                <span className="main-loader__wrapper-logo">H</span>
                <span className="main-loader__wrapper-logo">E</span>
                <span className="main-loader__wrapper-logo">A</span>
                <span className="main-loader__wrapper-logo">T</span>
                <span className="main-loader__wrapper-logo">M</span>
                <span className="main-loader__wrapper-logo">E</span>
                <span className="main-loader__wrapper-logo">A</span>
                <span className="main-loader__wrapper-logo">L</span>
            </div>
        </div>
    </div>
)

export default MainLoader;