import React, { useReducer } from 'react';
import { ADD_NEW_STEP, NEXT_STEP, PREV_STEP, SET_CALLBACKS, TRANING_SET_ACTIVE } from '../types';
import { TrainingContext } from './TrainingContext';
import { trainingReducer } from './TrainingReducer';

const isActive = () => {
    if(!JSON.parse(localStorage.getItem('training'))) {
        localStorage.setItem('training', true)
        return true;
    }
    return !localStorage.getItem('training') == 'false';
}

export const TrainingState = ({ children }) => {
    const initialState = {
        isActive: false,
        currentStep: 0,
        steps: [],
    }

    const [state, dispatch] = useReducer(trainingReducer, initialState);

    const setActive = (active) => {
        localStorage.setItem('training', active);
        dispatch({ type: TRANING_SET_ACTIVE, active });
    }

    const stopTraining = () => {
        localStorage.setItem('training', false);
        dispatch({ type: TRANING_SET_ACTIVE, active: false });
    }

    const startTraining = () => {
        localStorage.setItem('training', true);
        dispatch({ type: TRANING_SET_ACTIVE, active: true });
    }

    const setNewStep = (step) => {
        dispatch({ type: ADD_NEW_STEP, step })
    }

    const nextStep = () => {
        dispatch({ type: NEXT_STEP })
    }

    const prevStep = () => {
        dispatch({ type: PREV_STEP })
    }

    const setStepCallback = (data) => {
        dispatch({ type: SET_CALLBACKS, ...data })
    }
    
    return (
        <TrainingContext.Provider value={{
            ...state,
            setActive,
            setNewStep,
            nextStep,
            prevStep,
            setStepCallback,
            startTraining,
            stopTraining
        }}>
            {children}
        </TrainingContext.Provider>
    )
}
