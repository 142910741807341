import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../store/app/AppContext';
import { UserContext } from '../../store/user/UserContext'
import classNames from 'classnames';
import { Link, Route } from 'react-router-dom';
import hocRoute from '../../hoc/route'
import Login from './login';
import ProfileInfo from './ProfileInfo';
import Registration from './registration';
import ProfileChange from './profileChange';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function Profile (props) {
    const {
        hideFooterMobile,
        setHideFooterMobile,
        showProfileHeader,
        profileHeaderTitle,
        showProfileHeaderButton
    } = useContext(AppContext)
    const { user } = useContext(UserContext)

    useEffect(() => {
        if(!hideFooterMobile) setHideFooterMobile();
    }, []);

    return (
        <div className={classNames('page', { [`page--${user.gender}`]: true })}>
            {showProfileHeader && 
                <div className='page__header'>
                    {showProfileHeaderButton && <Link to={hocRoute('profile.info')} className="page__header-back">
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </Link>}
                    <Link className='page__header-back' to={hocRoute('profile.info')}></Link>
                    <div className='page__title'>{profileHeaderTitle}</div>
                </div>}
            <div className={classNames('profile', { [`profile--${user.gender}`]: true })}>
                <Route exact={true} path={hocRoute('profile.info')} component={ProfileInfo} />
                <Route path={hocRoute('profile.login')} component={Login} />
                <Route path={hocRoute('profile.register')} component={Registration} />
                <Route path={hocRoute('profile.change')} component={ProfileChange} />
            </div>
        </div>
    );
}