import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Tabbar from '../../components/Tabbar';
import Profile from '../../pages/Profile';
import Channels from '../../pages/Channels';
import Progress from '../../pages/progress'
import Support from '../../pages/Support/dialog'
import Login from '../../pages/Profile/login';
import { Auth } from '../../pages/Auth';
import Politics from '../../pages/Politics';
import { TrainingContext } from '../../store/training/TrainingContext';

const DefaultContainer = (props) => {
    const { isActive } = useContext(TrainingContext)

    return (
        <div className="Layout" style={{ pointerEvents: isActive ? 'none': 'auto' }}>
            <Header modificators={props.header || []} {...props} />
            <main>
                <Route path="/profile" component={Profile} />
                <Route path="/support" component={Support} />
                <Route path="/channels" component={Channels} />
                <Route exact path="/" component={Progress} />
                <Route path="profile/login" render={(props) => <Login {...props} />} />
                <Route path="/auth/:token" component={() => Auth(props)} />
                <Route path="/politics" component={() => Politics(props)} />
            </main>
            <Footer />
            <Tabbar />
        </div>
    )
}

export default DefaultContainer
