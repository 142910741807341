import React, {useContext, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';
import DefaultContainer from '../Routes/DefaultContainer'
import routes from '../routes.json'
import ScrollToTop from './ScrollToTop';
import { AppState } from '../../store/app/AppState';
import { ModalState } from '../../store/modal/ModalState';
import {history} from '../history';
import LoggedInRoute from '../loggedInRoute';
import { DialogState } from '../../store/dialog/DialogState';
import { UserContext } from '../../store/user/UserContext';
import { ChannelsState } from '../../store/channels/ChannelsState'
import MainLoader from '../../components/MainLoader';
import { DialogsState } from '../../store/dialogs/DialogsState';
import { ProgressState } from '../../store/progress/ProgressState';
import { TrainingState } from '../../store/training/TrainingState';
import Training from '../../components/Training'
import { TrainingContext } from '../../store/training/TrainingContext';
import { getStorageActive } from '../../utils/training';
import Offer from '../../modals/Offer';

function Layout () {
    const { initUser, pending, isAuthorized } = useContext(UserContext);
    const { isActive, startTraining } = useContext(TrainingContext);

    const isAuthPage = useRouteMatch('/auth/:token')

    useEffect(() => {
        if(!isAuthPage) {
            initUser();
        }
        
    }, [isAuthPage])
    
    const containers = {
        default: DefaultContainer
    }

    const middlewares = {
        loggedIn: LoggedInRoute,
        default: Route
    }   

    
    
    return (
        <>
            {!pending ? 
                <>
                    <TrainingState>
                    <ChannelsState>
                        <DialogsState>
                            <ModalState>
                                <ProgressState>
                                    <Router history={history}>
                                        <ScrollToTop />
                                        <Switch>
                                            {Object.entries(routes).map((item, key) => {
                                                let route = item[1];
                                                let Component = containers[route.container] || containers.default;
                                                let Middleware = middlewares[route.middleware] || middlewares.default;
                                                return (
                                                    <Middleware
                                                    path={"/" + route.path}
                                                    exact={route.exact || false}
                                                    component={(props) => (
                                                                <AppState>
                                                                    <DialogState>
                                                                            <Training>
                                                                                <Component {...route.params} title={route.title} />
                                                                            </Training>
                                                                    </DialogState>
                                                                </AppState>
                                                        )}
                                                        key={key}
                                                    />
                                                    )
                                                })}
                                        </Switch>
                                    </Router>
                                </ProgressState>
                            </ModalState>
                        </DialogsState>
                    </ChannelsState>
                    </TrainingState>
                </>
                 : <MainLoader />}
        </>
    );
};

export default Layout;
