let links = [
    {
        "route": "channels.list",
        "text": "Каналы",
        "icon": `<svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23 14.3517H1C0.447998 14.3517 0 13.9037 0 13.3517C0 12.7997 0.447998 12.3517 1 12.3517H23C23.552 12.3517 24 12.7997 24 13.3517C24 13.9037 23.552 14.3517 23 14.3517Z" fill="white"/>
        <path d="M23 5.72656H1C0.447998 5.72656 0 5.27856 0 4.72656C0 4.17456 0.447998 3.72656 1 3.72656H23C23.552 3.72656 24 4.17456 24 4.72656C24 5.27856 23.552 5.72656 23 5.72656Z" fill="white"/>
        <path d="M23 22.9765H1C0.447998 22.9765 0 22.5285 0 21.9765C0 21.4245 0.447998 20.9765 1 20.9765H23C23.552 20.9765 24 21.4245 24 21.9765C24 22.5285 23.552 22.9765 23 22.9765Z" fill="white"/>
        </svg>
        `,
    },
    {
        "route": "support",
        "text": "Поддержка",
        "icon": `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="30px" height="30px" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="nonzero" clip-rule="evenodd" viewBox="0 0 10240 10240" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>user_headset icon</title>
        <desc>user_headset icon from the IconExperience.com O-Collection. Copyright by INCORS GmbH (www.incors.com).</desc>
        <path id="curve2" fill="#fff" d="M8320 8470c0,810 -1920,1130 -3200,1130 -1280,0 -3200,-320 -3200,-1129 0,-1760 582,-2029 1663,-2835 445,281 972,444 1537,444 138,0 274,-10 407,-29 75,19 153,29 233,29 298,0 568,-137 745,-354 52,-29 102,-59 152,-90 1082,807 1663,1074 1663,2834z"/>
        <path id="curve1" fill="#fff" d="M5024 5737c-840,-31 -1578,-466 -2022,-1115 122,-117 198,-281 198,-462l0 -1920c0,-54 -7,-108 -20,-160 398,-690 1135,-1120 1940,-1120 805,0 1542,430 1940,1120 -13,52 -20,106 -20,160l0 1920c0,14 0,28 1,43 -74,72 -164,133 -250,182 -90,52 -186,95 -284,132 -178,-221 -451,-357 -747,-357 -530,0 -960,430 -960,960 0,235 84,450 224,617z"/>
        <path id="curve0" fill="#fff" d="M5760 4480c276,0 511,175 601,420 220,-60 419,-140 589,-237 188,-107 338,-234 438,-373 -18,-40 -28,-84 -28,-130l0 -1920c0,-66 20,-127 54,-177 -419,-843 -1289,-1423 -2294,-1423 -1005,0 -1875,580 -2294,1423 34,50 54,111 54,177l0 1920c0,176 -144,320 -320,320l-320 0c-352,0 -640,-287 -640,-640l0 -1280c0,-353 287,-640 640,-640l300 0c471,-948 1449,-1600 2580,-1600 1131,0 2109,652 2580,1600l300 0c353,0 640,287 640,640l0 1280c0,352 -288,640 -640,640l-320 0c-11,0 -23,-1 -34,-2 -127,176 -311,333 -539,463 -205,117 -447,213 -715,281 -49,305 -313,538 -632,538 -353,0 -640,-287 -640,-640 0,-353 287,-640 640,-640z"/>
    </svg>`,
    },
    {
        "route": "profile.info",
        "text": "Профиль",
        "icon": `<svg version="1.1" width="23" height="22" fill="#fff" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <g>
            <g>
                <path d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148
                    C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
                    c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
                    h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40
                    c59.551,0,108,48.448,108,108S315.551,256,256,256z"/>
            </g>
        </g>
        </svg>
        `,
    },
    {
        "route": "progress",
        "text": "Прогресс",
        "icon": `<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.29 8.45212C11.0097 8.18399 10.554 8.18262 10.2723 8.45074L7.90615 10.7154L6.97752 9.82574C6.69721 9.55762 6.24152 9.55762 5.96121 9.82574C5.6809 10.0939 5.6809 10.5297 5.96121 10.7979L7.39871 12.1729C7.53815 12.3076 7.72215 12.375 7.90615 12.375C8.09015 12.375 8.27415 12.3076 8.41502 12.1742L11.29 9.42424C11.5703 9.15612 11.5703 8.72024 11.29 8.45212Z" fill="white"/>
        <path d="M11.29 13.9521C11.0097 13.684 10.554 13.6826 10.2723 13.9507L7.90615 16.2154L6.97752 15.3271C6.69721 15.059 6.24152 15.059 5.96121 15.3271C5.6809 15.5952 5.6809 16.0311 5.96121 16.2992L7.39871 17.6742C7.53815 17.8076 7.72215 17.875 7.90615 17.875C8.09015 17.875 8.27415 17.8076 8.41502 17.6742L11.29 14.9242C11.5703 14.6561 11.5703 14.2202 11.29 13.9521Z" fill="white"/>
        <path d="M16.5312 9.625H13.6562C13.2595 9.625 12.9375 9.933 12.9375 10.3125C12.9375 10.692 13.2595 11 13.6562 11H16.5312C16.928 11 17.25 10.692 17.25 10.3125C17.25 9.933 16.928 9.625 16.5312 9.625Z" fill="white"/>
        <path d="M16.5312 15.125H13.6562C13.2595 15.125 12.9375 15.433 12.9375 15.8125C12.9375 16.192 13.2595 16.5 13.6562 16.5H16.5312C16.928 16.5 17.25 16.192 17.25 15.8125C17.25 15.433 16.928 15.125 16.5312 15.125Z" fill="white"/>
        <path d="M18.6875 2.75H15.8125V2.0625C15.8125 1.683 15.4905 1.375 15.0938 1.375H13.5341C13.2365 0.57475 12.4373 0 11.5 0C10.5627 0 9.7635 0.57475 9.46594 1.375H7.90625C7.5095 1.375 7.1875 1.683 7.1875 2.0625V2.75H4.3125C3.52044 2.75 2.875 3.36737 2.875 4.125V20.625C2.875 21.3826 3.52044 22 4.3125 22H18.6875C19.4796 22 20.125 21.3826 20.125 20.625V4.125C20.125 3.36737 19.4796 2.75 18.6875 2.75ZM8.625 2.75H10.0625C10.4592 2.75 10.7812 2.442 10.7812 2.0625C10.7812 1.683 11.1032 1.375 11.5 1.375C11.8968 1.375 12.2188 1.683 12.2188 2.0625C12.2188 2.442 12.5407 2.75 12.9375 2.75H14.375V4.125H8.625V2.75ZM18.6875 20.625H4.3125V4.125H7.1875V4.8125C7.1875 5.192 7.5095 5.5 7.90625 5.5H15.0938C15.4905 5.5 15.8125 5.192 15.8125 4.8125V4.125H18.6875V20.625Z" fill="white"/>
        </svg>
        `,
    }, 
]

export default links