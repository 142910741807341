import { useLocation, Route, useParams } from 'react-router-dom';
import hocRoute from '../../hoc/route';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../store/app/AppContext';
import Article from './article';
import ChannelsSidebar from '../../components/Channels/ChannelsSidebar';
import Channel from '../../components/Channels/Channel';
import { UserContext } from '../../store/user/UserContext';
import classNames from 'classnames';
import { ChannelsContext } from '../../store/channels/ChannelsContext';
import { DialogsContext } from '../../store/dialogs/DialogsContext';
import { TrainingContext } from '../../store/training/TrainingContext';
import { getStorageActive } from '../../utils/training';
import { ModalContext } from '../../store/modal/ModalContext';

export default function Chats(props) {

    const { user } = useContext(UserContext);
    const { isActive, startTraining } = useContext(TrainingContext)
    let location = useLocation();
    let isMenu = location.pathname === hocRoute('channels.list');
    const channelType = location.pathname.split('/')[2];
    const { showModal, hideModal } = useContext(ModalContext)

    // const isModalShow = () => {
    //     if(!user.categories.find((item) => item.alias === 'premium')) {
    //         if(user.categories.find((item) => item.alias === 'newbee')) {
    //             if(!JSON.parse(localStorage.getItem('modalShowed'))) {
    //                 console.log(!JSON.parse(localStorage.getItem('modalShowed')));
    //                 localStorage.setItem('modalShowed', 'true');
    //                 return true;
    //             }

    //             return false
    //         }
    //         return true;
    //     } 
    //     return false;
    // }

    // useEffect(() => {
    //     if(user.categories) {
    //         isModalShow() && showModal('offert');
    //         location.pathname === hocRoute('politics') && hideModal('offert')
    //     }
    // }, [user]); 

    const {
        headerTitle,
        setHeaderMobile,
        hideFooterMobile,
        fixedHeaderMobile,
        setHideFooterMobile,
        setFixedHeaderMobile,
    } = useContext(AppContext)

    const { connectToChannel, disconnectSocket } = useContext(ChannelsContext)
    const { channels } = useContext(DialogsContext)
    const currentChannel = connectToChannel ? channels.find((item) => item.alias === channelType) : null

    useEffect(() => {
        currentChannel && connectToChannel(currentChannel.id)

        return () => disconnectSocket();
    }, [currentChannel])
    
    useEffect(() => {
        if(getStorageActive()) {
            startTraining()
        }
    }, [])

    useEffect(() => {
        window.ttq.load('C56DHMBG5HFBPDLO1CSG');
        window.ttq.page();
    }, [])

    useEffect(() => {
        if(headerTitle && isMenu) setHeaderMobile(false);
        if(fixedHeaderMobile && isMenu) setFixedHeaderMobile(false);
        if(!hideFooterMobile) setHideFooterMobile();
    }, [isMenu, headerTitle, setHeaderMobile, fixedHeaderMobile, setFixedHeaderMobile]);

    return (
        <section className="channels__section section">
            <div className="section__container container">
                <div className={classNames("channels", {[`channels--${user.gender}`]: true})} style={{height: 'auto'}}>
                    <ChannelsSidebar />
                    {isMenu ? '' :
                        <div className="channels__content">
                            <Route exact path={hocRoute('channels.view', ':current')} component={Channel}/>
                            <Route path={hocRoute('channels.article', ':current', ':id')} component={Article}/>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}