export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const SET_TABBAR_BUTTON = "SET_TABBAR_BUTTON";
export const SET_HEADER_MOBILE = "SET_HEADER_MOBILE";

export const HIDE_FOOTER_MOBILE = "HIDE_FOOTER_MOBILE";
export const FIXED_HEADER_MOBILE = "FIXED_HEADER_MOBILE";

export const SHOW_PROFILE_HEADER = "SHOW_PROFILE_HEADER";

export const MODAL_SET = "MODAL_SET";
export const MODAL_HIDE = "MODAL_HIDE";
export const MODAL_SHOW = "MODAL_SHOW";
export const MODAL_TOGGLE = "MODAL_TOGGLE";

export const USER_FETCH_INIT = "USER_FETCH_INIT";
export const USER_READY = "USER_READY";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_FETCH_LOGIN = "USER_FETCH_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_WATCH_SUCCESS = "USER_WATCH_SUCCESS";
export const USER_JOIN_SPONSOR = "USER_JOIN_SPONSOR";
export const USER_JOIN_SPONSOR_ERROR = "USER_JOIN_SPONSOR_ERROR";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";
export const USER_FETCH_ERROR = "USER_FETCH_ERROR";
export const USER_UPDATE_CALORIES = "USER_UPDATE_CALORIES";
export const UPDATE_USER = "UPDATE_USER";

export const DIALOG_GET = "DIALOG_GET";
export const DIALOG_OPEN = "DIALOG_OPEN";
export const DIALOG_EMPTY = "DIALOG_EMPTY";
export const DIALOG_PENDING = "DIALOG_PENDING";
export const DIALOG_GET_USER = "DIALOG_GET_USER";
export const DIALOG_SHOW_IMAGE = "DIALOG_SHOW_IMAGE";
export const DIALOG_INPUT_TEXT = "DIALOG_INPUT_TEXT";
export const DIALOG_ADD_HISTORY = "DIALOG_ADD_HISTORY";
export const DIALOG_TOGGLE_CLIP = "DIALOG_TOGGLE_CLIP";
export const DIALOG_HIDE_MESSAGE = "DIALOG_HIDE_MESSAGE";
export const DIALOG_CHANGE_SCROLL = "DIALOG_CHANGE_SCROLL";
export const DIALOG_CHANGE_TYPING = "DIALOG_CHANGE_TYPING";
export const DIALOG_MODIFY_UNREAD_MESSAGES = "DIALOG_MODIFY_UNREAD_MESSAGES";
export const DIALOG_WITH_ERROR = "DIALOG_WITH_ERROR";
export const HIDE_TABBAR_MOBILE = "HIDE_TABBAR_MOBILE";

export const DIALOGS_GET = "DIALOGS_GET";
export const DIALOGS_PENDING = "DIALOGS_PENDING";
export const DIALOGS_IS_UPDATED = "DIALOG_IS_UPDATED";
export const DIALOGS_FILTERED_GET = "DIALOGS_FILTERED_GET";
export const DIALOGS_UPDATE_CHATS = "DIALOGS_UPDATE_CHATS";
export const DIALOGS_UPDATE_FIXED = "DIALOG_UPDATE_FIXED";
export const DIALOG_DELETE_MESSAGE = "DIALOG_DELETE_MESSAGE";
export const DIALOGS_DISCONNECT = "DIALOGS_DISCONNECT";

export const SET_CURRENT_ACTICLE = "SET_CURRENT_ARTICLE";
export const CHANNELS_LOADING = "CHANNELS_LOADING";
export const CHANNELS_LOADING_ERROR = "CHANNELS_LOADING_ERROR";
export const CHANNELS_LOADING_SUCSSES = "CHANNELS_LOADING_SUCSSES";
export const SET_ARTICLES = "SET_ARTICLES";

export const SET_CHANNELS = "CHANNELS_SET_CHANNELS";
export const SET_CHANNELS_MESSAGES = "SET_CHANNELS_MESSAGES";
export const SET_CHART = "SET_CHART";
export const CHANNELS_PENDING = "CHANNELS_PENDING";
export const CHANNELS_DISCONNECT = "CHANNELS_DISCONNECT";
export const CHANNELS_DELETE_MESSAGE = "CHANNELS_DELETE_MESSAGE";

export const SET_MESSAGE_CHANNELS = "SET_MESSAGE_CHANNELS";
export const RESET_MESSAGE_COUNT = "RESET_MESSAGE_COUNT";

export const PROGRESS_LOADING = "PROGRESS_LOADING";
export const PROGRESS_ERROR = "PROGRESS_ERROR";
export const PROGRESS_SET_ACTIVITIES = "PROGRESS_SET_ACTIVITIES";
export const SET_CURRENT_DAY = "SET_CURRENT_DAY";
export const ADD_NEW_ACTIVITY = "ADD_NEW_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const SET_NEW_DAY = "SET_NEW_DAY";
export const RESET_ARTICLES = "RESET_ARTICLES";

export const TRANING_SET_ACTIVE = "TRANING_SET_ACTIVE";
export const ADD_NEW_STEP = "ADD_NEW_STEP";
export const NEXT_STEP = "NEXT_STEP";
export const PREV_STEP = "PREV_STEP";
export const SET_CALLBACKS = "SET_CALLBACKS";

export const USER_FIRST_PAY_LOADING = "USER_FIRST_PAY_LOADING";
export const USER_FIRST_PAY_SUCCESS = "USER_FIRST_PAY_SUCCESS";
export const USER_FIRST_PAY_ERROR = "USER_FIRST_PAY_ERROR";

export const WS = {
  CONNECT: "WS_CONNECT",
  CONNECTING: "WS_CONNECTING",
  CONNECTED: "WS_CONNECTED",
  DISCONNECT: "WS_DISCONNECT",
  DISCONNECTED: "WS_DISCONNECTED",
  NEW_MESSAGE: "WS_NEW_MESSAGE",
};
