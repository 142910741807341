import { useContext } from "react"
import { UserContext } from "../store/user/UserContext"

export const prepareActivities = (activities) => {
    return Object.keys(activities).reduce((acc, item) => ([ ...acc, { value: item, label: activities[item]}]), [])
}

const kcal = {
    walk: 0.075,
    run: 0.172,
    bike: 0.1283,
    burpee: 0.134,
    squats: 0.0933,
    push_ups: 0.132,
    plank: 0.083,
    hiit: 0.1567,
}

const physicalValues = {
    none: 1.2,
    small: 1.375,
    medium: 1.55,
    large: 1.72
}

export const UseUpdateUser = () => {
    const { user, updateCalories, updateUserCalories } = useContext(UserContext)

    const calcActivity = (activity, time) => {
        return Math.trunc(kcal[activity] * time * user.question.weight * physicalValues[user.question.physical]);
    }

    const addActivity = (calories) => {
        updateCalories({
            lost_calories_today: user.lost_calories_today - calories,
            lost_calories_last_week: user.lost_calories_last_week - calories,
            lost_calories_last_month: user.lost_calories_last_month - calories
        })
    }

    const removeActivity = (calories) => {
        updateUserCalories({
            lost_today: user.calories.lost_today + calories,
            lost_week: user.calories.lost_week + calories,
            lost_month: user.calories.lost_month + calories
        })
    }

    return {
        addActivity,
        removeActivity,
        calcActivity
    }
}