import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../store/app/AppContext';
import { UserContext } from '../../store/user/UserContext';
import Button from '../../UI/Button'
import hocRoute from '../../hoc/route'

function Registration() {
    const {
        headerTitle,
        setHeaderMobile,
        hideFooterMobile,
        setHideFooterMobile,
        setHideTabbarMobile,
        hideTabbarMobile,
        setShowProfileHeader,
    } = useContext(AppContext)

    useEffect(() => {
        setShowProfileHeader(false)
        if(!hideFooterMobile) setHideFooterMobile();
        if(!hideTabbarMobile) setHideTabbarMobile();
    }, []);

    return (
            <div className='profile__registration'>
                <h1 className='profile__login-title'>
                    Регистрация
                </h1>
                <form className='profile__form'>
                    <input className='profile__form-input' placeholder='Имя' />
                    <input className='profile__form-input' placeholder='e-mail' />
                    <input className='profile__form-input' placeholder='пароль' />
                    <input className='profile__form-input' placeholder='повторить пароль' />
                    <Button className='profile__form-button'>Регистрация</Button>
                    <span className='profile__form-register'>Есть аккаунт? <a href={hocRoute('profile.login')}>вход</a></span>
                </form>
            </div>
    )
}

export default Registration
