import React, { useCallback, useContext, useMemo } from 'react'
import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { TrainingContext } from '../../store/training/TrainingContext'

const Pointer = ({ container, styles, next, noBlock }) => {
    const pointer = <div className='training-pointer' style={styles}></div>

    const onClick = useCallback(() => next(), []);
    
    useEffect(() => {
        if(container) {
            container.style.position = 'relative';
            container.style.pointerEvents = 'auto';
            container.style.overflow = 'visible';

            container.addEventListener('click', onClick, { once: true });
        }

        return () => {
            if(container) {
                container.style.position = '';

                if(!noBlock) 
                    container.style.pointerEvents = '';
                
                container.style.overflow = '';

                container.removeEventListener('click', onClick);
            }
        }
    }, [container])
    
    return container ? ReactDOM.createPortal(pointer, container) : '';
}

const Text = ({ container, textValue, styles }) => {
    const text = <p className='training-text' style={styles}>{textValue}</p>

    useEffect(() => {
        if(container)
            container.style.position = 'relative';

        return () => {
            if(container)
                container.style.position = '';
        }
    }, [container])

    return container ? ReactDOM.createPortal(text, container) : '';
}

const Arrow = ({ container, styles }) => {
    const arrow = <span className='training-arrow' style={styles}></span>
    
    useEffect(() => {
        if(container) 
            container.style.position = 'relative';
        
        return () => {
            if(container) 
                container.style.position = '';
            
        }
    }, [container])
    
    return container ? ReactDOM.createPortal(arrow, container) : '';
}

function Training({ children }) {
    const { currentStep, steps, isActive, nextStep } = useContext(TrainingContext)

    const step = useMemo(() => steps.find((item) => item.index === currentStep), [steps, currentStep]);
    
    return (
        <>
            {children}
            {isActive && step && 
                <>
                    <>
                        {step.elements.pointer?.map((item, index) => (
                            <Pointer key={index} noBlock={item.noBlock} next={nextStep} container={item.ref} styles={item.styles} />
                        ))}
                    </>
                    <>
                        {step.elements.text?.map((item, index) => (
                            <Text key={index} container={item.ref} styles={item.styles} textValue={item.text} />
                        ))}
                    </>
                    <>
                        {step.elements.arrow?.map((item, index) => (
                            <Arrow key={index} container={item.ref} styles={item.styles} />
                        ))}
                    </>
                </>
            }
        </>
    )
}

export default Training
