import { NavLink, useHistory } from "react-router-dom";
import Route from "../../hoc/route";
import moment from 'moment';
import { useWindowSize } from "../../utils/useWindowSize";
import React, { useContext, useEffect, useMemo } from 'react'
import { DialogsContext  } from '../../store/dialogs/DialogsContext'
import { prepareTextObj } from "../../utils/channels";
import { trainingConfigs, UseTraining, UseTrainingConfigs } from "../../utils/training";
import { TrainingContext } from "../../store/training/TrainingContext";

export default React.memo(function ChatBar() {
    const [width, height] = useWindowSize()
    const { list } = useContext(DialogsContext)
    const isDisplayNone = width <= 768 && document.location.pathname !== Route('channels.list')
    const channels = list.filter((item) => item.type === 'channel');
    const history = useHistory();
    const { isActive, currentStep, steps } = useContext(TrainingContext)

    const { register, setStepCallback } = UseTraining();

    useEffect(() => {
        if(width >= 650 || width === 0) {
            setStepCallback({ stepIndex: 0, nextCb: () => history.push(Route('channels.view', 'eat_channel')) })
            setStepCallback({ stepIndex: 1, prevCb: () => history.push(Route('channels.list')), nextCb: () => history.push(Route('channels.view', 'training_channel')) })
            setStepCallback({ stepIndex: 2, prevCb: () => history.push(Route('channels.view', 'eat_channel')), nextCb: () => history.push(Route('channels.view', 'info_channel')) })
            setStepCallback({ stepIndex: 3, prevCb: () => history.push(Route('channels.view', 'training_channel')) })
        } else {
            setStepCallback({ stepIndex: 0, prevCb: () => history.push(Route('channels.list')), nextCb: () => history.push(Route('channels.view', 'eat_channel')) })
            setStepCallback({ stepIndex: 1, prevCb: () => history.push(Route('channels.list')), nextCb: () => history.push(Route('channels.list')) })
            setStepCallback({ stepIndex: 2, prevCb: () => history.push(Route('channels.view', 'eat_channel')), nextCb: () => history.push(Route('channels.view', 'training_channel')) })
            setStepCallback({ stepIndex: 3, prevCb: () => history.push(Route('channels.list')), nextCb: () => history.push(Route('channels.list')) })
            setStepCallback({ stepIndex: 4, prevCb: () => history.push(Route('channels.view', 'training_channel')), nextCb: () => history.push(Route('channels.view', 'info_channel')) })
            setStepCallback({ stepIndex: 5, prevCb: () => history.push(Route('channels.list')), nextCb: () => history.push(Route('channels.list')) })
            setStepCallback({ stepIndex: 6, prevCb: () => history.push(Route('channels.view', 'info_channel'))  })
        }
    }, [width]);

    const configs = UseTrainingConfigs();

    const registers = useMemo(() => {
        return [
            register(configs.channels[0], 1),
            register(configs.channels[1], 3),
            register(configs.channels[2], 3),
            register(configs.channels[3], 4),
            register(configs.channels[3], 5),
            register(configs.channels[3], 6),
            register(configs.channels[4], 7),
        ]
    }, [width]);
    
    return (
        <div className="channels__sidebar" style={{ display: isDisplayNone ? 'none': 'block' }}>
            <div className="channels__title" >Ваши каналы</div>
            <div className="channels__list" ref={registers[6]}>
                <>
                    {channels.length && channels.map((item, index) => (
                        <NavLink key={index} ref={registers[index]} to={Route("channels.view", item.alias)} className="channels__list-item">
                            <div className={`article__header-avatar article__header-avatar--${item.alias}`}>
                            </div>
                            <div className="channels__list-item-info">
                                <span className="channels__list-item-name">{item.title}</span>
                                <span className="channels__list-item-message">{item.last_message && prepareTextObj(item.last_message?.text).title}</span>
                            </div>
                            <div className="channels__list-item-meta">
                                <span className="channels__list-item-date">{item.last_message?.time && moment.unix(item.last_message?.time).format('HH:mm')}</span>
                                {item.unread_messages_count > 0 && !isActive && <span className='channels__list-item-count'>{item.unread_messages_count}</span>}
                                {isActive && <span ref={registers[index + 3]} className='channels__list-item-count'>5</span>}
                            </div>
                        </NavLink>
                    ))}
                </> 
            </div>
        </div>
    )
})
