import { useReducer } from 'react';
import { CHANNELS_LOADING, CHANNELS_DISCONNECT, CHANNELS_LOADING_ERROR, CHANNELS_LOADING_SUCSSES, CHANNELS_PENDING, SET_ARTICLES, SET_CHANNELS_MESSAGES, SET_CURRENT_ACTICLE, CHANNELS_DELETE_MESSAGE } from '../types';
import { ChannelsContext } from './ChannelsContext';
import { channelsReducer } from './ChannelsReducer';
import { getApi } from '../../utils/api'
import { prepareMessages } from '../../utils/channels';
import { getToken } from '../../utils/getToken';
import { configs } from '../../utils/config';

export const ChannelsState = ({ children }) => {
    const api = getApi();
    let config = configs.getToken();
    let token = getToken();

    let channelSoket = null;

    const initialState = {
        currentArticle: {
            id: '',
            title: '',
            text: '',
            preview: ''
        },
        articles: [],
        loading: false,
        error: false
    };

    const [state, dispatch] = useReducer(channelsReducer, initialState)

    const onMessage = (event, id) => {
        let data = JSON.parse(event.data)
        if(data.messages) {
            let messages = prepareMessages(data.messages);
            dispatch({ type: SET_CHANNELS_MESSAGES, messages: messages.filter(item => item.chat_id ? id === parseInt(item.chat_id) : item) });
        }

        if(data.command?.action === 'hide')
            dispatch({ type: CHANNELS_DELETE_MESSAGE, id: data.command.message_id })
    }

    const onClose = store => (event) => {
        // console.log(event);
    }

    const onOpen = store => (event) => {
        dispatch({ type: CHANNELS_PENDING })
    }

    const connectToChannel = (channel_id) => {
        if (channelSoket !== null) channelSoket.close();

        // connect to the remote host
        channelSoket = new WebSocket(config.ws + `?token=${token}&chat_id=${channel_id}`);

        // webdialogsSocket handlers
        channelSoket.onmessage = (event) => onMessage(event, channel_id);
        channelSoket.onclose = onClose(123);
        channelSoket.onopen = onOpen(123);
    }

    const disconnectSocket = () => {
        channelSoket && channelSoket.close();
        dispatch({ type: CHANNELS_DISCONNECT });
    }
    
    const getCurrentArticle = async (id) => {
        try {
            dispatch({ type: SET_CURRENT_ACTICLE, article: {} })
            dispatch({ type: CHANNELS_LOADING })
            const response = await api.get(`/article/${id}`)
            dispatch({ type: SET_CURRENT_ACTICLE, article: response.data })
            dispatch({ type: CHANNELS_LOADING_SUCSSES })
        } catch(e) {
            dispatch({ type: CHANNELS_LOADING_ERROR })
        }   
    }

    const getArticles = async () => {
        try {
            dispatch({ type: CHANNELS_LOADING })
            const response = await api.get(`/articles`)
            dispatch({ type: SET_ARTICLES, articles: response.data })
            dispatch({ type: CHANNELS_LOADING_SUCSSES })
        } catch(e) {
            dispatch({ type: CHANNELS_LOADING_ERROR })
        } 
    }

    return (
        <ChannelsContext.Provider value={{
            ...state,
            getCurrentArticle,
            getArticles,
            connectToChannel,
            disconnectSocket
        }}>
            {children}
        </ChannelsContext.Provider>
    )
}