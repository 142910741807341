import React, { useContext, useMemo, useRef, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import classNames from 'classnames';
import { faInstagram, faVk } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Route from '../../hoc/route';
import links from '../Header/_menu';

import { AppContext } from '../../store/app/AppContext';
import { UserContext } from '../../store/user/UserContext';
import { ModalContext } from '../../store/modal/ModalContext';
import { UseNotify } from '../../utils/channels';
import { DialogsContext } from '../../store/dialogs/DialogsContext';
import ControlBar from '../Training/ControlBar';
import { trainingConfigs, UseTraining, UseTrainingConfigs } from '../../utils/training';

export default function Tabbar() {

    const {showModal} = useContext(ModalContext);
    const {isAuthorized, user} = useContext(UserContext);
    const { list } = useContext(DialogsContext)
    const { tabbarButton, hideTabbarMobile } = useContext(AppContext);
    const rootRef = useRef()

    const [showNotify, setShowNotify] = useState(false)

    const checkPrivate = (e, link) => {
        if(link.private && !isAuthorized) {
            e.preventDefault();
            showModal('login');
        }
    }

    const { channelsNotify, chatNotify } = UseNotify(list)

    const { register: trainingRegister, setStepCallback } = UseTraining();
    const configs = UseTrainingConfigs();

    const registers = useMemo(() => {
        return [
            null,
            null,
            trainingRegister(configs.tabbar[0], 1),
            trainingRegister(configs.tabbar[1], 2),
        ]
    }, []);

    if(hideTabbarMobile) return null;

    return (
        <>
            <ControlBar rootRef={rootRef.current} />
            <div className="tabbar" ref={rootRef}>
                <div className="tabbar__inner">
                    <div className="tabbar__button" id="tabbar-button">{tabbarButton}</div>
                    <div className="tabbar__menu">
                        {links.map((link, k) => {
                            if (link.text === 'Профиль' && !user.gender) return <></>
                            return (
                            <NavLink exact={link.route === 'progress'} ref={registers[k]} key={k} to={Route(link.route)} className="tabbar__menu-link" onClick={link.route == "notify.list" ? () => setShowNotify(prev => !prev) : (e) => checkPrivate(e, link)}>
                                {link.icon ? <span className="tabbar__menu-link-icon" dangerouslySetInnerHTML={ {__html : link.icon} }></span> : ''}
                                {link.text ? <span className="tabbar__menu-link-text">{link.text}</span> : ''}
                                {link.route === 'support' && chatNotify ? <span className='tabbar__menu-link-notify'>{chatNotify}</span> : ''}
                                {link.route === 'channels.list' && channelsNotify ? <span className='tabbar__menu-link-notify'>{channelsNotify}</span> : ''}
                            </NavLink>
                        )})}
                    </div>
                </div>
            </div>
        </>
    )
}