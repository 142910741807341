import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import Route from "../../hoc/route";
import links from './_menu';
import classNames from 'classnames';
import Button from "../../UI/Button";

import logo from '../../img/svg/logo.svg';

import { UserContext } from "../../store/user/UserContext";
import { AppContext } from "../../store/app/AppContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import Parameters from "../Parameters/Parameters";
import { DialogsContext } from "../../store/dialogs/DialogsContext";
import { UseNotify } from "../../utils/channels";
import ControlBar from "../Training/ControlBar";
import { UseTraining, UseTrainingConfigs, getStorageActive } from "../../utils/training";
import { TrainingContext } from "../../store/training/TrainingContext";
import { useWindowSize } from "../../utils/useWindowSize";
import Offer from "../../modals/Offer";
import { ModalContext } from "../../store/modal/ModalContext";
import Unsubscribe from '../../modals/Unsubscribe';
import SuccessUnsubscribe from '../../modals/SuccessUnsubscribe';

function Header (props) {
    let classes = props.modificators.map((c) => `header--${c}`);
    
    const [width] = useWindowSize();
    const { isAuthorized, user, logout } = useContext(UserContext);
    const { headerTitle, headerBack, headerBackParams, fixedHeaderMobile, hideTabbarMobile } = useContext(AppContext);
    const { connectToSocket, list, isConnected, disconnectSocket } = useContext(DialogsContext);
    const { startTraining, isActive } = useContext(TrainingContext);
    const { showModal, hideModal } = useContext(ModalContext)
    const history = useHistory();
    const location = useLocation();

    const { currentStep } = useContext(TrainingContext);

    const { channelsNotify, chatNotify } = UseNotify(list);

    const rootRef = useRef();

    const { register, setStepCallback } = UseTraining();

    useEffect(() => {
        if(getStorageActive() && isAuthorized) {
            history.push(Route('channels.list'))
        }
    }, [isAuthorized])

    useEffect(() => {
        if(!isActive) return
        if(width >= 650) {
            setStepCallback({ stepIndex: 4, nextCb: () => history.push(Route('profile.info')) })
            setStepCallback({ stepIndex: 5, prevCb: () => history.push(Route('channels.list')) })
            setStepCallback({ stepIndex: 10, prevCb: () => history.push(Route('profile.change')), nextCb: () => history.push(Route('progress')) })
        } else if(width !== 0) {
            setStepCallback({ stepIndex: 7, nextCb: () => history.push(Route('profile.info')) });
            setStepCallback({ stepIndex: 8, prevCb: () => history.push(Route('channels.list')) });
            setStepCallback({ stepIndex: 13, nextCb: () => history.push(Route('progress')) });
            setStepCallback({ stepIndex: 14, prevCb: () => history.push(Route('profile.change')) });
        }
    }, [width, isActive]); 

    useEffect(() => {
        !isConnected && connectToSocket(user.id);
    }, [user.id])   

    const onLogout = () => {
        logout(); 
        disconnectSocket();
    }

    const configs = UseTrainingConfigs()

    const fixedHeaderRegister = useMemo(() => {
        return width <= 650 && width !== 0 ? register(configs.fixedHeader.find((item) => item.step === currentStep) || {}, currentStep + 20) : null
    }, [currentStep, width]);

    const isModalShow = () => {
        if(
           user.categories.find((item) => item.alias === "premium") || 
           user.categories.find((item) => item.alias.includes("trial")) || 
           user.categories.find((item) => item.alias.includes("rebill_long")) ||
           user.categories.find((item) => item.alias.includes("rebill_short"))
        ) 
        return false;
        
        return true;
    }

    useEffect(() => {
        if(user.categories && isAuthorized) {
            isModalShow() && showModal('offert');
            location.pathname === Route('politics') && hideModal('offert')
        }
    }, [user, isAuthorized]); 

    const registers = useMemo(() => {
        return [
            null,
            null,
            register(configs.header[0], 2),
            register(configs.header[1], 3),
        ]
    }, [width]);

    const isControlBar = () => {
        if(width <= 768 && location.pathname === '/support') return false
        if(width >= 650) return true;

        if(hideTabbarMobile && location.pathname !== '/support') return true;
    }

    return (
        <>
        <header ref={rootRef} className={classNames("header", classes, {"header--mobile-fixed" : fixedHeaderMobile, [`header--${user.gender}`]: true})}>
            <div className="header__container container">
                {headerTitle === 'Вход' && 
                <div className="header__row header__row--mobile">
                    <Link to={Route('progress')} className="header__logo">
                        <img src={logo} alt="logo"/>
                    </Link>
                </div>}
                {headerTitle ?
                    <div className="header__row header__row--mobile">
                        <div className="header__mobile" ref={fixedHeaderRegister} >
                            <Link to={Route(headerBack, ...headerBackParams)} className="header__mobile-back">
                                <FontAwesomeIcon icon={faArrowLeft}/>
                            </Link>
                            <span>{headerTitle}</span>
                        </div>
                    </div>
                    : ''
                }
                <div className="header__row header__row--desktop">
                    <Link to={Route('progress')} className="header__logo">
                        <img src={logo} alt="logo"/>
                    </Link>
                    <div className="header__menu">
                        {links.filter(item => !item.tabbar).map((link, k) => {
                            if (link.text === 'Профиль' && !user.gender) return <></>
                            return (
                            <NavLink ref={registers[k]} exact={link.route === 'progress'} key={k} to={Route(link.route)} className="header__menu-link">
                                {link.icon ? <span className="header__menu-link-icon" dangerouslySetInnerHTML={ { __html : link.icon} }></span> : ''}
                                {link.text ? <span className="header__menu-link-text">{link.text}</span> : ''} 
                                {link.route === 'support' && chatNotify ? <span className='header__menu-link-notify'>{chatNotify}</span> : ''}
                                {link.route === 'channels.list' && channelsNotify ? <span className='header__menu-link-notify'>{channelsNotify}</span> : ''}
                            </NavLink>
                        )})}
                    </div>
                    <div className="header__auth" >
                        {
                            isAuthorized 
                            ?
                            <>
                                <div className="header__user">
                                    <div className={`header__user-avatar header__user-avatar--${user.gender}`}>
                                    </div>
                                    <Button
                                        color="transparent"
                                        size="small"
                                        onClick={onLogout}
                                    >Выход</Button>
                                </div>
                                <Parameters location='header' />
                            </>
                            :
                            <div className="header__buttons">
                                <Button
                                    color="main"
                                    size="small"
                                    to={Route('profile.login')}
                                >Логин</Button>
                                {/* <Button
                                    color="main"
                                    size="small"
                                >Регистрация</Button> */}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </header>
        {isControlBar() && <ControlBar rootRef={rootRef.current} />}
        <Offer />
        <Unsubscribe theme={user.gender} text='Вы уверенны, что хотите отменить текущую подписку?' />
        <SuccessUnsubscribe theme={user.gender} />
        </>
    );
}

export default React.memo(Header);