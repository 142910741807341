import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import debounce from 'javascript-debounce';

const checkReg = (reg, index) => {
    return reg ? reg[index] : '';
};

const removeAllAttr = (text) => {
    const reg = /<[\d\D]+\s(\w+="[^'"]+")[\s\S]\/>/m;

    while (text.match(reg)) {
        text = text.replace(text.match(reg)[1], '');
    }
};

const list = (text) => {
    text = text.replaceAll(/<span>([^<]+)<\/span>/g, (...args) => {
        return args[1].replace(/([а-яА-Я()]+)\s([\d\D]+\s[\d\D]+)/g, (...args) => {
            return `<div><span>${args[1]}</span> <span>${args[2]}</span></div>`;
        });
    });
    return text;
};

export const prepareArticle = (text) => {
    if (!text) return;

    let resultText = '';
    let i = 0;
    text = text.replaceAll('&lt;', '<');
    text = text.replaceAll('&gt;', '>');
    text = text.replaceAll('../', 'https://giveaways.roimaster.site/');
    text = text.replaceAll(/width: [^;]+;/gm, '');
    // text = text.replaceAll(/<!--([^<]+)-->/gm, '');
    // text = text.replaceAll(/\sstyle="[^"]+"/g, '');
    text = text.replaceAll('<img', '<img class="article__photo"');
    text = text.replaceAll('<iframe', '<iframe class="article__video"');

    // while (text.match(/\w/)) {
    //     if (i > 2 && !resultText) return text;

    //     text = text.replaceAll('<p>&nbsp;</p>', '')
    //     text = text.replace('<strong', '<strong class="article__title"');
    //     text = text.replace('<p>', '');
    //     text = text.replace('</p>', '');
    //     text = text.replace('<p>', '');
    //     text = text.replace('</p>', '');
    //     text = text.replace(
    //         '<p>',
    //         '<p class="article__content article__description">',
    //     );
    //     text = text.replace('<ul>', '<ul class="article__list-1">');
    //     text = text.replace('<p>', '<p class="article__time">');
    //     text = text.replace('<ul>', '<ul class="article__list-2">');
    //     // text = text.replace(
    //     //     /<ul class="article__list-2">[^=]+<\/ul>/g,
    //     //     list,
    //     // );
    //     text = text.replace(
    //         '<p>',
    //         '<p class="article__content article__description">',
    //     );
    //     text = text.replace('<ol>', '<ol class="article__list-3">');

    //     resultText = text.match(
    //         /<strong class="article__title"[\d\D]+<ol class="article__list-3">[^=]+<\/ol>/i,
    //     )
    //         ? resultText +
    //           text.match(
    //               /<strong class="article__title"[\d\D]+<ol class="article__list-3">[^=]+<\/ol>/i,
    //           )[0]
    //         : '';
    //     text = text.replace(
    //         /<strong class="article__title"[\d\D]+<ol class="article__list-3">[^=]+<\/ol>/,
    //         '',
    //     );
        
    //     i++;
    // }
    return text;
};

export const getPreview = (text) => {
    if (!text) return;

    return checkReg(
        text.match(/<img[\d\D]+(src=('|")([^'"]+)('|"))[\d\D]+\/>/m),
        1,
    );
};

export const prepareArticles = (list) => {
    return list.map((item) => ({ ...item, logo: getPreview(item.text) }));
};

export const prepareTextObj = (text) => {
    text = text.replace(/\[\[[\d\D]+\]\]/i, '');
    try {
        text = JSON.parse(text);
    } catch (e) {
        return text;
    }
    return text;
};

export const prepareMessages = (messages) => {
    return messages.map((item) => ({
        ...item,
        preview: prepareTextObj(item.text),
        human: {
            date: moment.unix(item.time).format('DD.MM.YY'),
            time: moment.unix(item.time).format('HH:mm'),
        },
    }));
};

export const UseNotify = (list) => {
    const [get, set] = useState({ channels: 0, chat: 0 });
    useEffect(() => {
        set({
            channelsNotify: list.reduce(
                (acc, item) =>
                    item.type === 'channel'
                        ? acc + item.unread_messages_count
                        : acc,
                0,
            ),
            chatNotify: list.reduce(
                (acc, item) =>
                    item.type === 'dialog'
                        ? acc + item.unread_messages_count
                        : acc,
                0,
            ),
        });
    }, [list]);

    return get;
};

const createMessageRefs = (list) => {
    return list.map((item) => ({ ...item, ref: React.createRef() }));
};

const isVisible = (el) => {
    const rect = el.getBoundingClientRect();
    const efp = function (x, y) {
        return document.elementFromPoint(x, y);
    };

    return (
        el.contains(efp(rect.left, rect.top)) ||
        el.contains(efp(rect.right, rect.top)) ||
        el.contains(efp(rect.right, rect.bottom)) ||
        el.contains(efp(rect.left, rect.bottom))
    );
};

export const UseAnchorMessage = (channelId, articles, loading, container) => {
    const [anchoreMessages, setAnchoreMessages] = useState(
        JSON.parse(localStorage.getItem('messages')),
    );
    const [messages, setMessages] = useState(
        anchoreMessages ? anchoreMessages[channelId] : [],
    );
    const [currentAnchoredMessage, setCurrentAnchoredMessage] = useState(0);
    const [selectedAnchor, setIsSelectedAnchor] = useState(false);
    const [isButtonActive, setIsButtonActive] = useState(false);

    const checkMessages = (list1, list2 = articles) => {
        return list1.sort((a, b) => b.time - a.time);
            // .reduce((acc, item) => {
            //     const currentItem = list2.find((item2) => item2.id === item.id);
            //     if (!currentItem) toggleMessage(item.id);
            //     return currentItem
            //         ? [...acc, { id: currentItem.id, ref: React.createRef(), time: currentItem.time, }]
            //         : acc;
            // }, [])
            
    };

    useEffect(() => {
        if (!anchoreMessages) {
            localStorage.setItem('messages', JSON.stringify(Array(3).fill([])));
            setAnchoreMessages(JSON.parse(localStorage.getItem('messages')));
        }

        if (!isNaN(channelId) && anchoreMessages && articles.length && !loading) {
            setMessages(createMessageRefs(anchoreMessages[channelId]));
        }

        messages && setCurrentAnchoredMessage(0);
    }, [channelId, anchoreMessages, loading, articles]);

    useEffect(() => {
        if (messages && !loading)
            setMessages(createMessageRefs(checkMessages(messages, articles)));
    }, [articles, loading, anchoreMessages]);

    const isBottomButtonActive = useCallback((e) => {
        e.target.scrollTop + 300 <= 0
            ? setIsButtonActive(true)
            : setIsButtonActive(false);
    }, []);

    const scrollBottom = () => {
        container.current.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (container.current) {
            container.current.addEventListener(
                'scroll',
                debounce(isBottomButtonActive, 150),
            );

        return () =>
            container.current?.removeEventListener(
                'scroll',
                isBottomButtonActive,
            );
        }
    }, [container]);

    // useEffect(() => {
    //   if(container && messages?.length && !loading) {
    //     console.log(messages[currentAnchoredMessage]);
    //     container.current.addEventListener('scroll', checkViewport)
    //   }

    //   return () => container.removeEventListener('scroll', checkViewport)
    // }, [container, messages, currentAnchoredMessage])

    const toggleMessage = (id) => {
        let newMessages;
        if (anchoreMessages[channelId].find((item) => item.id === id)) {
            newMessages = anchoreMessages.map((item, index) =>
                index === channelId
                    ? item.filter((message) => message.id !== id)
                    : item,
            );
        } else {
            newMessages = anchoreMessages.map((item, index) =>
                index === channelId ? [...item, { id }] : item,
            );
        }

        localStorage.setItem('messages', JSON.stringify(newMessages));
        setMessages(createMessageRefs(newMessages[channelId]));
        setAnchoreMessages(JSON.parse(localStorage.getItem('messages')));
    };

    const getRef = (id) => {
        const currentMessage = messages?.find((item) => item.id === id);
        return currentMessage ? currentMessage.ref : null;
    };

    const scrollToAnchor = (container) => {
        const anchor = messages[currentAnchoredMessage];
        if (!anchor) return;
        if(!anchor.ref?.current) toggleMessage(anchor.id)
        setIsSelectedAnchor(true);
        container.current.scrollTo({
            top: anchor.ref.current?.offsetTop - 220,
            behavior: 'smooth',
        });
        setTimeout(() => {
            setCurrentAnchoredMessage((prev) =>
                prev === messages.length - 1 ? 0 : prev + 1,
            );
            setIsSelectedAnchor(false);
        }, 500);
    };

    const isMessageSelected = (id) =>
        selectedAnchor && messages[currentAnchoredMessage]?.id === id;

    return {
        messages,
        toggleMessage,
        getRef,
        scrollToAnchor,
        isMessageSelected,
        currentAnchoredMessage,
        isButtonActive,
        scrollBottom,
    };
};

// text = text.replaceAll(checkReg(text.match(/<img[\d\D]+(style=("|')([^"']+)("|'))/m), 1), '');
// text = text.replaceAll(checkReg(text.match(/<img[\d\D]+(width=('|")[^'"]+('|"))[\d\D]+\/>/m), 1), '');
// text = text.replaceAll(checkReg(text.match(/<img[\d\D]+(height=('|")[\d\w]+('|"))[\d\D]+\/>/m), 1), '');
// text = text.replaceAll('<img', '<img class="article__list-item-photo"');
// console.log(text.replaceAll(/<[\d\D]+\s(\w+="[^'"]+")[\s\S]\/>/g, ''));
// removeAllAttr(text)
//   text = text.replaceAll('<h1', '<h1 class="article__list-item-title"');
//   text = text.replace('<span s', '<span class="article__list-item-decription"');
//   text = text.replace('<span s', '<span class="article__list-item-decription"');
//   text = text.replace('<span s', '<span class="article__list-item-decription"');
//   text = text.replace('<ul', '<ul class="article__characteristics"');
//   text = text.replaceAll('<iframe', '<iframe class="article__video"');
//   text = text.replaceAll('<p', '<p class="article__content"');

// console.log('p', text);
