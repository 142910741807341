import {
  USER_FETCH_LOGIN,
  UPDATE_USER,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_UPDATE,
  USER_READY,
  USER_WATCH_SUCCESS,
  USER_JOIN_SPONSOR,
  USER_UPDATE_SUCCESS,
  USER_FETCH_SUCCESS,
  USER_FETCH_ERROR,
  USER_FETCH_INIT,
  USER_UPDATE_CALORIES,
  USER_FIRST_PAY_LOADING,
  USER_FIRST_PAY_SUCCESS,
  USER_FIRST_PAY_ERROR,
} from "../types";

const handlers = {
  [USER_FETCH_LOGIN]: (state) => ({
    ...state,
    loading: true,
    errors: state.errors,
  }),
  [USER_LOGIN_ERROR]: (state, error) => ({ ...state, error, loading: false }),
  [USER_LOGIN_SUCCESS]: (state, { token, user }) => ({
    ...state,
    user,
    token,
    loading: false,
    error: "",
    isAuthorized: true,
    ready: true,
    pending: false,
  }),
  [USER_FETCH_INIT]: (state) => ({ ...state, pending: true }),
  [USER_READY]: (state) => ({ ...state, pending: false, isAuthorized: false }),
  [USER_UPDATE]: (state, { data }) => ({
    ...state,
    loading: true,
    success: false,
    user: {
      ...state.user,
      ...data,
      question: { ...state.user.question, ...data },
    },
  }),
  [USER_UPDATE_SUCCESS]: (state, { data }) => ({
    ...state,
    loading: false,
    success: true,
    user: { ...state.user, ...data },
  }),
  [USER_LOGOUT]: (state) => ({
    ...state,
    user: {},
    info: {},
    token: false,
    isAuthorized: false,
  }),
  [USER_JOIN_SPONSOR]: (state) => ({
    ...state,
    user: {
      ...state.user,
      roles: [...state.user.roles, "sponsor_moderated"],
    },
    loading: false,
  }),
  [USER_WATCH_SUCCESS]: (state) => ({
    ...state,
    user: {
      ...state.user,
      roles: state.user.roles.filter((item) => item !== "sponsor_declined"),
    },
    loading: false,
  }),
  [USER_FETCH_SUCCESS]: (state) => ({
    ...state,
    errors: {
      login: "",
      reg: "",
      joinSponsor: "",
      forgotPass: "",
    },
    loading: false,
  }),
  [USER_FETCH_ERROR]: (state, error) => ({
    ...state,
    errors: { ...state.errors, ...error },
    loading: false,
  }),
  [USER_UPDATE_CALORIES]: (state, { newCalories }) => ({
    ...state,
    user: { ...state.user, ...newCalories },
  }),
  [UPDATE_USER]: (state, { data }) => ({
    ...state,
    user: { ...state.user, calories: { ...state.user.calories, ...data } },
  }),
  [USER_FIRST_PAY_LOADING]: (state) => ({
    ...state,
    firstPayLoading: true,
  }),
  [USER_FIRST_PAY_SUCCESS]: (state) => ({
    ...state,
    firstPayLoading: false,
  }),
  [USER_FIRST_PAY_ERROR]: (state) => ({
    ...state,
    firstPayLoading: false,
  }),
  DEFAULT: (state) => state,
};

export const userReducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state, action);
};
